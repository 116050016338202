import {DYNAMIC_DETAILS_FAILED,DYNAMIC_DETAILS_SUCCESS,DYNAMIC_DETAILS_REQUEST} from '../../constants/common/companydetailsConstant'


export const companydetailsAction = (data)=> async (dispatch)=>{
try {
    dispatch({ type: DYNAMIC_DETAILS_REQUEST })
        dispatch({ type: DYNAMIC_DETAILS_SUCCESS, payload: data })
} catch (error) {
    dispatch({ type: DYNAMIC_DETAILS_FAILED, payload: error })

}
}