import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import Breadcrumb from '../../../BidGrid/components/BidBreadCrumb/Breadcrumb';
import { DynmicompanyDetails } from '../../../Services/dynmiccompanydetails';
import { companydetailsAction } from '../../../Redux/actions/common/companydetailsAction';
const BACKEND_BASE_URL = "http://localhost:8000/";


const AboutSettings = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const location = useLocation();
    const val = location?.pathname;
    const str = val.replace('/', '');
    const { companydetails } = useSelector((state) => state?.companyData);
    const [logoFile, setLogoFile] = useState(null);
    console.log(logoFile,"logofile")
    const [faviconFile, setFaviconFile] = useState(null);
    console.log(faviconFile,"faviconFile")

    const handleLogoChange = ({ file }) => {
        if (file) {
            setLogoFile(file.originFileObj);
        }
    };

    const handleFaviconChange = ({ file }) => {
        console.log(file,"file")
        if (file) {
            setFaviconFile(file.originFileObj);
        }
    };

    const onFinish = async (values) => {
        const formData = new FormData();
        formData.append('title', values.title);
        if (logoFile) {
            formData.append('logo_file', logoFile);
        }
        if (faviconFile) {
            formData.append('fav_icon', faviconFile);
        }
        try {
            const response = await DynmicompanyDetails.SetDetails(formData);
            if (response?.data?.status === '1') {
                fetchSeoData();
                message.success('Changes saved successfully!');
            }
        } catch (error) {
            message.error('Failed to save changes. Please try again.');
        }
    };

    const fetchSeoData = async () => {
        try {
            const response = await DynmicompanyDetails.GetDetails();
            if (response?.data?.status === '1') {
                dispatch(companydetailsAction(response?.data?.data));
                message.success('Data fetched successfully!');
            }
        } catch (error) {
            message.error('Failed to fetch SEO data.');
        }
    };


    useEffect(() => {
        if (companydetails?.fav_icon) {
            const link = document.querySelector("link[rel~='icon']");
            const fullIconPath = BACKEND_BASE_URL + companydetails.file_path + companydetails.fav_icon;
            
            if (!link) {
                const newLink = document.createElement('link');
                newLink.rel = 'icon';
                newLink.href = fullIconPath;
                document.getElementsByTagName('head')[0].appendChild(newLink);
            } else {
                link.href = fullIconPath;
            }
        }
    }, [companydetails]);
    

    useEffect(() => {
        fetchSeoData();
    }, []);

    return (
        <>
            <Breadcrumb data={str} />
            <div className="div" style={{ paddingTop: '100px' }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{ title: 'Default Title' }}
                    style={{ maxWidth: 600, margin: '0 auto', padding: '20px' }}
                >
                    <Form.Item
                        label="Website Title"
                        name="title"
                        rules={[{ required: true, message: 'Please input the title!' }]}
                    >
                        <Input placeholder="Enter website title" />
                    </Form.Item>

                    <Form.Item label="Logo" name="logo">
                        <Upload
                            name="logo"
                            listType="picture"
                            // beforeUpload={() => false}
                            multiple={false}
                            onChange={handleLogoChange}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item label="Favicon" name="favicon">
                        <Upload
                            name="favicon"
                            listType="picture"
                            // beforeUpload={() => false}
                            onChange={handleFaviconChange}
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default AboutSettings;
