// @ts-nocheck
import React, { useEffect, useState } from 'react';
import "../../assets/css/app.scss"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Menu, Select, Table, Input, Dropdown, Spin, Modal, Card, Collapse, Form, Row, Col } from 'antd';
import { Down, More, Record } from '@icon-park/react';
import { ArrowDownOutlined, CopyOutlined, CarryOutFilled, ClockCircleOutlined } from '@ant-design/icons';
import Model from '../Models/Model';
import Delete from '../modalDelete/Delete';
import ExportDatatable from './ExportDatatable';
import { saveAs } from 'file-saver';
import InvoicePdf from 'common/components/InvoicePdf/InvoicePdf';
import { pdf } from '@react-pdf/renderer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import AddRequestDrawer from '../Drawer/AddRequestDrawer';
import { DashboardServiceApi } from 'Services/bidgrid/dashboard/DashboardAPi';
import { capitalizeExceptPrepositionsAndLowerCase } from 'common/components/CapitalLetter/CapitalLetterWithoutPrepositions';
import ROUTES from 'Constants/Routes';
// import capitalizeExceptPrepositionsAndLowerCase from '../../../common/components/CapitalLetter/CapitalLetterWithoutPrepositions'
const { Search } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const DataTable = (props) => {

  const { title, setOpenMeeting, setOpenDrawer, setgenerateReqDrawer, generateReqDrawer, columnLabels, dataSource, TenderId, setShowReminderModal,
    handleOpenEditDrawer, showActions, showExpire, handleDelete, handleUpdate, sectorOptions, getPropData, tenderList, getreminder, getAddUnitHandler,
    businessUnitTimezone, ActionIcon, setDoctrolOpen, TenderDetailid, getPropDataVal, meetingSchedule, spinner, setSpinner, BankList, setTeamRequisitionOpen,
    teamReqAccordianShow, dataSourceAccordian, columnsAccordian, modalButtonName, infoId, getMeetinglist,getPermission } = props;

  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  // const [loadingData, setLoadingData] = useState(true);
  const [sortedInfo, setSortedInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewModal, setViewModal] = useState(false)
  const [viewModalData, setViewModalData] = useState([])
  // const [spinner, setSpinner] = useState(false)
  const [reminderModalRowData, setReminderModalRowData] = useState({})
  const [tick, setTick] = useState()
  const [meeting, setmeeting] = useState(false)
  const { userBidInfo } = useSelector((state) => state.userDetails)
  const navigate = useNavigate()
  const { id } = useParams();
  const location = useLocation();
  const val = location?.pathname;
  const str = val?.replace('/', '')
  const { BidCountry } = useSelector((state) => state.bidDropdownCalVal)

  const pdfName = location?.pathname?.split('/')
  const getPdfName = pdfName[pdfName?.length - 1]

  const filteredData = props?.dataSource?.filter((item) => {
    return Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    );
  })
    ?.map((item, index) => ({
      ...item,
      srNo: pageSize * (currentPage - 1) + index + 1,
    }));

  const generatePdfDocument = async (datasource, format) => {
    const dataa = [datasource]
    if (dataa?.length === 1) {
      const blob = await pdf((
        <InvoicePdf
          dataSource={dataa}
          columnLabels={columnLabels}
          optionVal={format}
        />

      )).toBlob();
      saveAs(blob, String(getPdfName.charAt(0).toUpperCase() + getPdfName.slice(1)))
    }

  };


  const showDeleteModal = (record) => {
    setDeleteModal(true)
    setModalData(record)
  }

  const ShowViewModal = (record) => {
    setViewModalData(record)
    setViewModal(true)
  }
  const handleReminderModal = (record) => {
    setShowReminderModal(record)
  }
  const handleDownloadPdf = (format, record) => {
    generatePdfDocument(record, format);
  }
  const handleCopyLink = (data) => {
    setTick(data?.srNo)
    setTimeout(() => {
      setTick()
    }, 1000);
    const tempInput = document.createElement('input');
    tempInput.value = data?.link?.props?.href;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };
  // setOpenMeeting
  const showModal = (record) => {
      setReminderModalRowData(record)
      if (getPropData) {
        getPropData(record);
        setOpen(false);
      } else {
        setModalData(record);
        setOpen(true);
      }
  };

  const PermissionAllow = (record)=>{
    if (getPermission) {
      getPermission(record);
      setOpen(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const documentBlobReq = async (doc_name, apiUrl) => {
    const fullUrl = window.location.href;
    const urlObject = new URL(fullUrl);
    const protocol = urlObject.protocol;
    const hostname = urlObject.host;
    const domain = `${protocol}//${hostname}`;
    const proxyUrl = `${domain}/proxy?url=${encodeURIComponent(apiUrl)}`;
    const response = await fetch(proxyUrl)
    const blobData = await response?.blob()
    const fileURL = window.URL.createObjectURL(blobData);
    let alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = doc_name;
    alink.click();
  }

  const documentDownload = (item) => {
    const docAuth = 'https://demo4.growthgrids.com//'
    const apiUrl = `${docAuth}${item?.doc_path}/${item?.doc_name}`;
    documentBlobReq(item?.doc_name, apiUrl)
  }
  const documentFileDownload = (item) => {
    console.log(item, "item")
    const docAuth = 'https://demo4.growthgrids.com//'
    const apiUrl = `${docAuth}${item?.file_path}/${item?.file_data}`;
    documentBlobReq(item?.file_data, apiUrl)
  }

  const handleReminderModalUpdate = (updatedData) => {
    setOpen(false);
  };

  const columns = [
    {
      title: 'Sr. No',
      dataIndex: 'srNo',
      key: 'srNo',
      sorter: (a, b) => a.srNo - b.srNo,
      sortOrder: sortedInfo.columnKey === 'srNo' && sortedInfo.order,
      width: 120,
    },
 
    ...Object.keys(columnLabels).map((key) => ({
      title: columnLabels[key].name,
      dataIndex: key,
      key: key,
      sorter: (a, b) => {
        const valueA = a[key];
        const valueB = b[key];
        

        if (key === 'tender_name') {
          const valueA = JSON.stringify(a[key]);
          const valueB = JSON.stringify(b[key]);

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            // Sort strings
            return valueA.localeCompare(valueB);
          }
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Sort strings
          return valueA.localeCompare(valueB);
        } else if (valueA instanceof Date && valueB instanceof Date) {
          // Sort dates
          return valueA - valueB;
        } else if (valueA instanceof Number && valueB instanceof Number) {
          // Sort numbers
          return valueA - valueB;
        } else {
          // Fallback to default comparison
          return valueA - valueB;
        }
      },
      width: columnLabels[key].width,
      sortOrder: sortedInfo.columnKey === key && sortedInfo.order,
      render: (text) => ({
        children: capitalizeExceptPrepositionsAndLowerCase(text),
      }),
    })),
  ];

  if (showExpire) {
    columns.push({
      title: 'Expire Date',
      key: 'expireDate',
      sorter: (a, b) => a.expireDate - b.expireDate,
      sortOrder: sortedInfo.columnKey === 'expireDate' && sortedInfo.order,
      render: (record) => (
        <>
          <div className='expire_date_doc'>
            {record?.doc_expiry_days}
          </div>
        </>
      )
    })
  }



  const handlemeeting = (val) => {
    setmeeting(true)
    setModalData(val)

  }
  

  if (showActions) {
    columns.push({
      title: 'Actions',
      key: 'actions',
    
      render: (record) => (
                <Dropdown
                  placement='bottomRight'
                  overlay={
                    <Menu className="bd_tableAction">
                          <Menu.Item key="edit" onClick={() => showModal(record)} className='bd_view_btn'>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_221_1663)">
                                <path d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.875 1.87505C14.1734 1.57668 14.578 1.40906 15 1.40906C15.422 1.40906 15.8266 1.57668 16.125 1.87505C16.4234 2.17342 16.591 2.57809 16.591 3.00005C16.591 3.422 16.4234 3.82668 16.125 4.12505L9 11.25L6 12L6.75 9.00005L13.875 1.87505Z" stroke="#636363" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_221_1663">
                                  <rect width="18" height="18" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            Edit
                          </Menu.Item>
                          <Menu.Item key="edit" onClick={() => PermissionAllow(record)} className='bd_view_btn'>
                          <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#icon-bb3d6d4d91d71d5)"><circle cx="15" cy="33" r="8" fill="none" stroke="#000" stroke-width="3"/><path d="M29 16L36 22" stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M20 26L36 8L43 14" stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="icon-bb3d6d4d91d71d5"><rect width="48" height="48" fill="#000"/></clipPath></defs></svg>
                            Role Permissions
                          </Menu.Item>
                    </Menu>
                  }
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <More theme="outline" size="25" fill="#95a6b6" strokeWidth={3} />
                  </a>
                </Dropdown >   
      ),
      width: 120,
    });
  }

  const selectOptions = {
    pdf: 'PDF',
    csv: 'CSV',
    print: 'Print',
  };

  const handleViewCancel = () => {
    setViewModal(false);
  };
  const [panelStates, setPanelStates] = useState({
    keyProffesional: true,
    keyProffesional1: false,

  });

  const togglePanel = (panelKey) => {
    setPanelStates((prevState) => ({
      ...prevState,
      [panelKey]: !prevState[panelKey],
    }));
  };

  const handleKeyPress = (e) => {
    const alphabeticChars = /[a-zA-Z]/;
    const forbiddenChars = /[{}.\[\]""''`~;:,0-9\-_@#$%^&*()<>?/|+=!\\]/;
    if (forbiddenChars.test(e.key) && alphabeticChars.test(e.key)) {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      //  handleProjectInfo()
    } else if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };


  return (
    <>
      
          <div className="table_wrap">
            <div className="tableHead_wrap">
              <Search
                placeholder="Search"
                allowClear
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 340 }}
                onKeyPress={handleKeyPress}
              />
              <div className="showPrPage">
                <ExportDatatable
                  dataSource={dataSource}
                  columnLabels={columnLabels}
                />
                <span>Showing</span>
                <Select
                  suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                  defaultValue={pageSize}
                  style={{ width: 70 }}
                  onChange={handlePageSizeChange}
                >

                  <Option value={10}>10</Option>
                  <Option value={20}>20</Option>
                  <Option value={30}>30</Option>
                  <Option value={40}>40</Option>
                </Select>
                <span>of {filteredData?.length} results</span>
                {title == "Team Requisition" &&
                  <button className='BG_mainButton' onClick={() => setTeamRequisitionOpen(true)}><PlusOutlined style={{ color: "white" }} /> Add Designation Category</button>
                }
              </div>
            </div>

            <div className="tableBody_wrap">
              {spinner ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                  <Spin size="small" />
                </div>
              ) : (

                <Table
                  // bordered
                  columns={columns}
                  dataSource={filteredData?.length === 0 ? null : filteredData}
                  pagination={{
                    pageSize: pageSize,
                  }}
                  scroll={{ x: 'max-content' }}
                  onChange={(pagination, filters, sorter) => setSortedInfo(sorter)}
                />
              )}
            </div>
          </div>
       
      <Delete title={title} open={deleteModal} handleDelete={handleDelete} getList={props.getList} onClose={() => setDeleteModal(false)} modalData={modalData} />
      <Modal title="View Document" open={viewModal} className="bd_model_main shareModal"
        onCancel={handleViewCancel}
        footer={null}
      >
        {
          viewModalData?.documentData?.length > 0 ?
            <>
              {
                viewModalData?.documentData?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Card
                        style={{
                          margin: 20,
                          paddingBlock: 0,
                        }}

                      >
                        <span style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => documentDownload(item)}> {item?.doc_name}  <ArrowDownOutlined /></span>
                      </Card>
                    </React.Fragment>
                  )
                })
              }
            </>
            :
            <>
              <p style={{ padding: '20px 0px 20px 15px' }}>Document Not Available</p>
            </>
        }
      </Modal>
      </>
  )
}
export default DataTable;



