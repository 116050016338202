// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, DatePicker, Input, Tabs } from 'antd';
import { Down } from '@icon-park/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from 'BidGrid/components/BidBreadCrumb/Breadcrumb';
import { useLocation } from 'react-router';
import { ProfileDetails } from 'Services/Profile/ProfileDetails';
import { useSelector } from 'react-redux'
import { userDetailsApi } from 'Services/bidgrid/master/profile/bidProfile';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { AddTenderList } from 'Services/bidgrid/add/AddTender';
import dayjs from 'dayjs';
import PasswordChangeBids from './PasswordChangeBid';
import { useDispatch } from 'react-redux';
import { userBidInfoAction } from 'Redux/actions/common/userInfoAction';
import moment from 'moment';

const { TextArea } = Input;

const intialState = {
  country_id: null,
  state_id: null,
  city_id: null,
  date_of_birth: null,
  full_address_1: '',
  zip_code: '',
  password: '',
  confirmPassword: "",
  old_password: '',
}

const Profile = () => {
  const [form] = Form.useForm();
  const [formdata] = Form.useForm();
  const [userName, setUserName] = useState([]);
  const [skeleton, setSkeleton] = useState(true)
  const { bidgridData } = useSelector((state) => state.loginData)
  const { BidCountry } = useSelector((state) => state.bidDropdownCalVal)
  const { userBidInfo } = useSelector((state) => state.userDetails)
  const dispatch = useDispatch()
  const [personalInputData, setPersonalInputData] = useState(intialState)
  const [profileState, setProfileState] = useState([])
  const [profileCity, setProfileCity] = useState([])
  const notify = (error) => toast.error(error);
  const notifySuccess = (msg) => toast.success(msg);

  const disableFutureDates = (current) => {
    // Disable all dates after today
    return current && current > moment().endOf('day');
  };
  const BdUserdetails = async () => {
    const formData = new URLSearchParams();
    formData.append('user_id', Number(bidgridData?.data?.id))
    try {
      const response = await ProfileDetails.getuserdetails(formData);
      if (response?.data?.status == 1) {
        setUserName(response.data.data)
        dispatch(userBidInfoAction({
          ...userBidInfo,
          profileimg: response.data.data?.profileimg,
          profileimg_path: response.data.data?.profileimg_path
          // Add other properties from userBidInfo as needed
        }));


      }
      else {
        setUserName([])
        notify(response?.response?.data?.message)
      }
    } catch (error) {
      console.log(error, 'api error');
    }
  };

  const fetchUserUpdateDetails = async (value) => {
    try {
      const formdata = new URLSearchParams();
      formdata.append('update_user_id', Number(userName?.id))
      formdata.append('firstname', userName?.firstname)
      formdata.append('lastname', userName?.lastname)
      formdata.append('contactnumber', userName?.contactnumber)
      formdata.append('role_id', Number(userName?.emp_role_id))
      formdata.append('company_id', Number(userName?.company_id))
      formdata.append('department_id', Number(userName?.department_id))
      formdata.append('designation_id', Number(userName?.designation_id))
      formdata.append('jobgrade_id', Number(userName?.jobgrade_id))
      formdata.append('reporting_mngr_id', Number(userName?.reporting_mngr_id))
      formdata.append('business_unit_id', Number(userName.unit_id));
      formdata.append('email', userName?.email)
      // formdata.append('password', userName?.password)
      formdata.append('country_id', value?.country_id)
      formdata.append('state_id', value?.state_id)
      formdata.append('city_id', value?.city_id)
      formdata.append('full_address_1', value?.full_address_1)
      formdata.append('zip_code', value?.zip_code)
      // formdata.append('profileimg', personalInputData?.profileImg !== null ? personalInputData?.profileImg : '')
      formdata.append('date_of_birth', value?.date_of_birth !== null ? dayjs(value?.date_of_birth)?.format('YYYY-MM-DD') : '')
      const response = await userDetailsApi.updateUserDetail(formdata);


      if (response?.data?.status == 1) {
        // setPersonalInputData(intialState)
        BdUserdetails()
        handleReset()
        notifySuccess(response?.data?.message)
      }
      else {
        // notify(response?.response?.data?.message);
        // handleReset()
      }
    } catch (error) {
      console.log(error, 'Api Error');
    }
  }

  const getAddTenderState = async () => {
    const formData = new URLSearchParams();
    formData.append('country_id', personalInputData?.country_id);
    try {
      const response = await AddTenderList.getTenderStateList(formData)
      if (response?.data?.status === '1') {
        setProfileState(response?.data?.data)
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  const getAddTenderCity = async () => {
    const formData = new URLSearchParams();
    formData.append('state_id', personalInputData?.state_id);
    try {
      const response = await AddTenderList.getTenderCityList(formData)

      if (response?.data?.status === '1') {
        setProfileCity(response?.data?.data)
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }

  useEffect(() => {
    getAddTenderState()
  }, [personalInputData?.country_id])

  useEffect(() => {
    getAddTenderCity()
  }, [personalInputData?.state_id])

  const handleReset = () => {
    setPersonalInputData('');
    formdata?.resetFields()
  };

  const handleInputChange = (name, value, date) => {
    if (name === "country_id") {
      formdata?.setFieldsValue({
        state_id: '',
        city_id: ''
      })
    }
    if (name === "state_id") {
      formdata?.setFieldsValue({
        city_id: ''
      })
    }
    setPersonalInputData(
      {
        ...personalInputData,
        [name]: value
      })
  }

  const location = useLocation();
  const val = location?.pathname;
  const str = val.replace('/', '')

  useEffect(() => {
    BdUserdetails()
  }, [])

  useEffect(() => {
    setSkeleton(true)
    const timer = setTimeout(() => setSkeleton(false), 1000);
    return () => {
      clearTimeout(timer)
    }
  }, [form?.length])

  // passord api --- khushbu
  const postPasswordChange = async (handleResetChangePassword) => {
    const { password, confirmPassword, old_password } = personalInputData;
    try {
      const formData = new URLSearchParams();
      formData.append('user_id', userBidInfo?.id)
      formData.append('old_password', old_password);
      await form.validateFields();
      const response = await userDetailsApi?.oldPassword(formData);
      if (response.data.status === '1') {
        if (password === confirmPassword) {
          const formData = new URLSearchParams();
          formData.append('user_id', userBidInfo?.id)
          formData.append('password', password);
          await form.validateFields();
          const response = await userDetailsApi?.changePassword(formData);
          if (response.data.status === '1') {
            setPersonalInputData(response?.data?.message);
            notifySuccess(response?.data?.message)
            handleReset()
            handleResetChangePassword()
          }
          else {
            notify(response?.response?.data?.message);
          }
        }
        else {
          notify('Passwords do not match or do not meet criteria');
        }
      }
      else {
        console.log(response, 'response')
        notify("Old Password is incorrect!!");
      }
    } catch (error) {
      notify("Old Password is incorrect!!");
      console.log("Server error")
    }
  }
  const getEditDetails = () => {
    const newObj = {
      // profileImg: userName?.profileimg || '',
      country_id: userName?.country_id || '',
      state_id: userName?.state_id || '',
      city_id: userName?.city_id || '',
      date_of_birth: userName?.date_of_birth !== null ? dayjs(userName?.date_of_birth) : null,
      full_address_1: userName?.full_address_1 || '',
      zip_code: userName?.zip_code

    }
    setPersonalInputData((prevState) => {
      return {
        ...prevState,
        ...newObj
      };
    });
    form.setFieldsValue(newObj);
    formdata.setFieldsValue(newObj);
  }

  useEffect(() => {

    if (userName?.country_id) {
      getEditDetails()
    }
  }, [userName])

  function handleImageChange(event) {
    const file = event.target.files[0];
    updateProfilePicture(file)
  }

  const updateProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append('user_id', Number(bidgridData?.data?.id))
    formData.append('files', file);
    try {
      const response = await userDetailsApi.updateUserProfilePic(formData);
      if (response?.data?.status === '1') {
        BdUserdetails()
        notifySuccess('Logo updated successfully');
      } else {
        notify(response?.response?.data?.message);
      }
    } catch (error) {
      console.error('API Error:', error);
      notify('Error updating logo');
    }
  }

  const validateZipCode = (_, value) => {
    if (value && value.length > 6) {
      return Promise.reject('Zip code must not exceed 6 characters');
    }
    return Promise.resolve();
  };

  return (
    <>
      <>
        <Breadcrumb data={str} />
        <div className="bd_profile_main">
          <div className='bd_main_profile_headings'>My Profile</div>
          <div className='bd_main_add' >
            <div className='bd_main_sub_add_profile'>
              <div className='bd_main_sub_image'>
                <img alt='' className="img-thumbnail rounded-circle"
                  src={`https://doc.mystorepedia.com/${userBidInfo.profileimg_path}/${userBidInfo.profileimg}`}
                />
                <label className='bd_main_update_profile_btn'>
                  Update Profile
                  <input
                    className='input_update_file'
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div className="line_through"></div>
              <div className='bd_main_sub_info'>
                {skeleton ?
                  <>
                    <div className='sub_emp_id'>
                      <Skeleton variant="rectangular" width={120} height={25} />
                      <Skeleton variant="rectangular" width={100} height={25} />
                    </div>
                    <div className='sub_contact'>

                      <p className='sub_pera'> <span className='sub_span'>Email:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
                      <p className='sub_pera'> <span className='sub_span'>Contact:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
                    </div>
                  </>
                  :
                  <>
                    <div className='sub_emp_id'>
                      <span className='sub_emp_head'>{userName.userfullname}</span>
                      <span className='sub_id'>{personalInputData?.role?.map(item => item?.role_name)}</span>
                    </div>
                    <div className='sub_contact'>
                      <p className='sub_pera'> <span className='sub_span'>Email:</span> {userName?.email}</p>
                      <p className='sub_pera'> <span className='sub_span'>Contact:</span>{userName?.contactnumber} </p>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          {/* <--------- tabs -------> */}
          <div className='bd_main_profile_tabs'>
            <div className='profile_tabs_heading'>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: 'Official',
                    key: '1',
                    children: <div className='profile_detail_info_box'>
                      <Form
                        // form={form}
                        layout="vertical"
                      >
                        <Row gutter={20}>

                          <Col sm={12} md={6} xs={24}>
                            <Form.Item label="First Name">
                              <Input placeholder="Enter Here" disabled={true} value={userName?.firstname} />
                            </Form.Item>
                          </Col>
                          <Col sm={12} md={6} xs={24}>
                            <Form.Item label="Last Name">
                              <Input placeholder="Enter Here" disabled={true} value={userName?.lastname} />
                            </Form.Item>
                          </Col>
                          <Col sm={12} md={6} xs={24}>
                            <Form.Item label="Offical Email ">
                              <Input placeholder="Enter Here" value={userName?.email} disabled={true} />
                            </Form.Item>
                          </Col>
                          <Col sm={12} md={6} xs={24}>
                            <Form.Item label="Mobile Number">
                              <Input placeholder="Enter Here" value={userName?.contactnumber} disabled={true} />
                            </Form.Item>
                          </Col>
                          <Col sm={12} md={6} xs={24}>
                            <Form.Item label="System Admin">
                              <Input placeholder="Enter Here" value={userName?.permanent_sys_adm === '1' ? 'Yes' : 'No'} disabled={true} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </div>,
                  },
                  {
                    label: 'Personal',
                    key: '2',
                    children: <div className='profile_detail_info_box'>
                      <div className='profile_sub_info'>
                        <Form
                          form={formdata}
                          layout="vertical"
                          // name="control-hooks"
                          autoComplete="off"
                          onFinish={fetchUserUpdateDetails}
                        >
                          <Row gutter={20}>
                            <Col sm={12} md={6} xs={24}>
                              <Form.Item label="DOB"
                                name='date_of_birth'
                                rules={[{ required: true, message: 'DOB is required' }]}>
                                <DatePicker
                                  name='date_of_birth'
                                  placeholder="Select Date"
                                  value={personalInputData?.date_of_birth ? moment(personalInputData.date_of_birth) : null}
                                  onChange={(value) => handleInputChange('date_of_birth', value)}
                                  disabledDate={disableFutureDates}
                                />
                              </Form.Item>
                            </Col>

                            <Col sm={12} md={6} xs={24}>
                              <Form.Item label="Country Id"
                                rules={[{ required: true, message: 'Country is required' }]}
                                name='country_id'

                              >

                                <Select
                                  showSearch
                                  value={personalInputData?.country_id}
                                  onChange={(value) => handleInputChange('country_id', value)}
                                  placeholder="Select Country"
                                  options={BidCountry?.map((item, index) => {
                                    return {
                                      value: item?.id,
                                      label: item?.country_name
                                    }
                                  })}
                                  suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                  filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col sm={12} md={6} xs={24}>
                              <Form.Item label="State Id "
                                rules={[{ required: true, message: 'State is required' }]}
                                name='state_id'

                              >
                                <Select
                                  showSearch
                                  value={personalInputData.state_id}
                                  onChange={(value) => handleInputChange('state_id', value)}
                                  options={profileState?.map((item, index) => {
                                    return {
                                      value: item?.id,
                                      label: item?.state_name
                                    }
                                  })}
                                  placeholder="Select State"
                                  // name='state_id'
                                  suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                  filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                >

                                </Select>
                              </Form.Item>
                            </Col>
                            <Col sm={12} md={6} xs={24}>
                              <Form.Item label="City Id"
                                rules={[{ required: true, message: 'City is required' }]}
                                name='city_id'
                              >
                                <Select
                                  showSearch
                                  value={personalInputData.city_id}
                                  onChange={(value) => handleInputChange('city_id', value)}
                                  options={profileCity?.map((item, index) => {
                                    return {
                                      value: item?.id,
                                      label: item?.city_name
                                    }
                                  })}
                                  // name='city_id'
                                  placeholder="Select City"
                                  suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                  filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                />

                              </Form.Item>
                            </Col>
                            <Col sm={12} md={6} xs={24}>
                              <Form.Item label="Zip Code"
                                name='zip_code'
                                rules={[{ required: true, message: 'Zip Code is required' },
                                { validator: validateZipCode }]}
                              >
                                <Input
                                  // name='zip_code'
                                  placeholder="Zip Code"
                                  value={personalInputData?.zip_code}
                                  onChange={(e) => handleInputChange('zip_code', e.target.value)}
                                />
                              </Form.Item>
                            </Col>

                            <Col sm={12} md={6} xs={24}>
                              <Form.Item label="Full Address"
                                rules={[{ required: true, message: 'Address is required' }]}
                                name='full_address_1'
                              >
                                <TextArea
                                  // name='full_address_1'
                                  placeholder="Full address"
                                  value={personalInputData?.full_address_1}
                                  onChange={(e) => handleInputChange('full_address_1', e.target.value)}
                                />
                              </Form.Item>
                            </Col>


                          </Row >
                          <div className='profile_button_sec'>
                            <button className='BG_ghostButton' onClick={handleReset}>Reset</button>
                            <button className='BG_mainButton' >Save & Change</button>
                          </div>
                        </Form >
                      </div >

                    </div >
                  },
                  {
                    label: 'Change Password',
                    key: '3',
                    children:
                      <PasswordChangeBids
                        form={form}
                        personalInputData={personalInputData}
                        handleInputChange={handleInputChange}
                        handleReset={handleReset}
                        postPasswordChange={postPasswordChange}
                        setPersonalInputData={setPersonalInputData}
                      />

                  },


                ]}
              />
            </div >
          </div >
        </div >
      </>


    </>
  )
}

export default Profile