// @ts-nocheck

const BIDGRIDROUTES = {
    BD_LOGIN: 'login',
    SETTING:'setting',
    ROLEPERMISSIONS: 'role-permissions/:id',
    DASHBOARD: "dashboard",
    PROFILE: "profile",
    EMP_PROFILE: 'profile/:id',
    document_share: "document",
    briefcase: "briefcase",
    EMPLOYEELIST: 'employeelist',
}
export default BIDGRIDROUTES;
