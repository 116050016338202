// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, DatePicker, Input, Tabs, Button } from 'antd';
import { Down } from '@icon-park/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from 'BidGrid/components/BidBreadCrumb/Breadcrumb';
import { useLocation, useParams } from 'react-router';
import { ProfileDetails } from 'Services/Profile/ProfileDetails';
import { useSelector } from 'react-redux'
import { userDetailsApi } from 'Services/bidgrid/master/profile/bidProfile';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { AddTenderList } from 'Services/bidgrid/add/AddTender';
import dayjs from 'dayjs';
import PasswordChangeBid from '../Profile/PasswordChangeBid';
const { TextArea } = Input;
const intialState = {
 // profileImg: "",
 firstname: '',
 lastname: '',
 company_id: null,
 email: '',
 contactnumber: '',
 password: '',
 country_id: null,
 state_id: null,
 city_id: null,
 date_of_birth: null,
 full_address_1: '',
 zip_code: '',
 old_password: '',
 confirmPassword: ""
}



const UserProfile = () => {
 const [form] = Form.useForm();
 const [userName, setUserName] = useState([]);
 const [skeleton, setSkeleton] = useState(true)
 const { BidCountry } = useSelector((state) => state.bidDropdownCalVal)
 const [showNotification, setShowNotification] = useState(false);

 const [personalInputData, setPersonalInputData] = useState(intialState)
 const [profileState, setProfileState] = useState([])
 const [profileCity, setProfileCity] = useState([])
 const notify = (error) => toast.error(error);
 const notifySuccess = (msg) => toast.success(msg);

 const { id } = useParams();





 const BdUserdetails = async () => {
  const formData = new URLSearchParams();
  formData.append('user_id', id)
  try {
   const response = await ProfileDetails.getuserdetails(formData);
   if (response?.data?.status == 1) {
    setUserName(response.data.data)
    const dataVal = response?.data?.data;
    const newObj = {
     firstname: dataVal?.firstname || '',
     lastname: dataVal?.lastname || '',
     role_id: dataVal?.emp_role_id || '',
     company_id: dataVal?.company_id || '',
     department_id: dataVal?.department_id || '',
     designation_id: dataVal?.designation_id || '',
     jobgrade_id: dataVal?.jobgrade_id || '',
     reporting_mngr_id: dataVal?.reporting_mngr_id || '',
     email: dataVal?.email || '',
     contactnumber: dataVal?.contactnumber || '',
     // password: dataVal?.password || '',
     unit_id: dataVal?.unit_id || '',
     country_id: dataVal?.country_id || '',
     state_id: dataVal?.state_id || '',
     city_id: dataVal?.city_id || '',
     date_of_birth: dataVal?.date_of_birth !== null ? dayjs(dataVal?.date_of_birth) : null,
     full_address_1: dataVal?.full_address_1 || '',
     zip_code: dataVal?.zip_code
    }


    setPersonalInputData((prevState) => {
     return {
      ...prevState,
      ...newObj
     };
    });
    form.setFieldsValue(newObj);

   }
   else {
    setUserName([])
    notify(response?.response?.data?.message)
   }
  } catch (error) {
   console.log(error, 'api error');
  }
 };
 const fetchUserUpdateDetails = async (value) => {
  setShowNotification(true);

  const formdata = new URLSearchParams();
  formdata.append('update_user_id', id)
  formdata.append('firstname', value?.firstname)
  formdata.append('lastname', value?.lastname)
  formdata.append('contactnumber', value?.contactnumber)
  formdata.append('role_id', 0)
  formdata.append('company_id', 0)
  formdata.append('department_id', 0)
  formdata.append('designation_id', 0)
  formdata.append('jobgrade_id', 0)
  formdata.append('reporting_mngr_id', 0)
  formdata.append('business_unit_id',0)
  formdata.append('email', value?.email)
  // formdata.append('password', value?.password)
  formdata.append('country_id', value?.country_id)
  formdata.append('state_id', value?.state_id)
  formdata.append('city_id', value?.city_id)
  formdata.append('full_address_1', value?.full_address_1)
  formdata.append('zip_code', value?.zip_code)

  formdata.append('date_of_birth', value?.date_of_birth !== null ? dayjs(value?.date_of_birth)?.format('YYYY-MM-DD') : '')
  try {
   const response = await userDetailsApi?.updateUserDetail(formdata);
   if (response?.data?.status == 1) {
    // setPersonalInputData(intialState)
    BdUserdetails()
    handleReset()
    notifySuccess(response?.data?.message)
   }
   else {
    notify(response?.response?.data?.message);
    // handleReset()
   }
  } catch (error) {
   console.log(error, 'Api Error');
  }
  // setTimeout(() => {
  //     setShowNotification(false);
  // }, 2000);
 }
 const getAddTenderState = async () => {
  const formData = new URLSearchParams();
  formData.append('country_id', personalInputData?.country_id);
  try {
   const response = await AddTenderList.getTenderStateList(formData)
   if (response?.data?.status === '1') {
    setProfileState(response?.data?.data)
   }
  } catch (error) {
   console.log(error, 'api erorr')
  }
 }
 const getAddTenderCity = async () => {
  const formData = new URLSearchParams();
  formData.append('state_id', personalInputData?.state_id);
  try {
   const response = await AddTenderList.getTenderCityList(formData)

   if (response?.data?.status === '1') {
    setProfileCity(response?.data?.data)
   }
  } catch (error) {
   console.log(error, 'api erorr')
  }
 }
 useEffect(() => {
  getAddTenderState()

 }, [personalInputData?.country_id])
 useEffect(() => {
  getAddTenderCity()
 }, [personalInputData?.state_id])
 const handleReset = () => {
  setPersonalInputData('');
  form?.resetFields()
 };
 const handleInputChange = (name, value) => {
  setPersonalInputData(
   {
    ...personalInputData,
    [name]: value
   })
 }
 const location = useLocation();
 const val = location?.pathname;
 const str = val.replace('/', '')
 useEffect(() => {
  setSkeleton(true)
  const timer = setTimeout(() => setSkeleton(false), 1000);
  return () => {
   clearTimeout(timer)
  }
 }, [form?.length])
 
 useEffect(() => {
  BdUserdetails()
 }, [])




 const { userBidInfo } = useSelector((state) => state.userDetails)
 
 

 return (
  <>
   <>
    <Breadcrumb data={str} />
    <div className="bd_profile_main">
     <div className='bd_main_profile_headings'>My Profile</div>
     <div className='bd_main_add' >
      <div className='bd_main_sub_add_profile'>
       <div className='bd_main_sub_image'>
       <img alt='' className="img-thumbnail rounded-circle"
         src={`https://doc.mystorepedia.com/${userName.profileimg_path}/${userName.profileimg}`}
        />
       </div>
       <div className="line_through"></div>
       <div className='bd_main_sub_info'>
        {skeleton ?
         <>
          <div className='sub_emp_id'>
           <Skeleton variant="rectangular" width={120} height={25} />
           <Skeleton variant="rectangular" width={100} height={25} />
          </div>
          <div className='sub_contact'>
          
           <p className='sub_pera'> <span className='sub_span'>Email:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
           <p className='sub_pera'> <span className='sub_span'>Contact:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
          </div>
         </>
         :
         <>
          <div className='sub_emp_id'>
           <span className='sub_emp_head'>{userName.userfullname}</span>
           <span className='sub_id'>{personalInputData?.role?.map(item => item?.role_name)}</span>
          </div>
          <div className='sub_contact'>
           <p className='sub_pera'> <span className='sub_span'>Email:</span> {userName?.email}</p>
           <p className='sub_pera'> <span className='sub_span'>Contact:</span>{userName?.contactnumber} </p>
          </div>
         </>
        }
       </div>
      </div>
     </div>
    
    </div>
   </>


  </>
 )
}

export default UserProfile