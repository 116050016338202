import axios from "axios";
import { bidBaseUrl } from "utils/configurable";



const SetDetails = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/seo-data-set`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const GetDetails = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.get(`${bidBaseUrl}/seo-data-get`,{ headers });
        return response;
    } catch (error) {
        return error;
    }
};


export const DynmicompanyDetails = {
    SetDetails,
    GetDetails
}