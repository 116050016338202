// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Dropdown, Upload, Modal, Col, Row, Flex, Tooltip, Spin, } from 'antd';
import { UploadOutlined, SettingOutlined } from '@ant-design/icons';
import { FolderPlus, DeleteOne, Search, LeftTwo, Right } from '@icon-park/react';
import fileclose from "../assets/images/fileclose.png"
import collapseImg from "../assets/images/minimize.png";
import fileTextImage from "../assets/images/txt-file.png";
import fileCSVImage from "../assets/images/sheets.png";
import fileVideoImage from "../assets/images/video.png";
import filePPTImage from "../assets/images/ppt.png";
import fileDocImage from "../assets/images/doc.png";
import filePDFImage from "../assets/images/pdf.png";
import fileImgImage from "../assets/images/image 17.png";
import universalImg from "../assets/images/attachment.png";
import xlsImg from '../../assests/img/xls.png'
import zoomIn from '../assets/images/zoom-in.png'
import zoomOut from '../assets/images/zoom-out.png'
import { toast } from 'react-toastify';
import folderIcon from "../assets/images/folder.png";
import { PlusOutlined } from '@ant-design/icons';
import Breadcrumb from "../components/BidBreadCrumb/Breadcrumb"
import { useLocation } from 'react-router';
import { Select, Tree } from 'antd';
import { briefcase } from 'Services/briefcase/briefcase';
import { capitalizeExceptPrepositionsAndLowerCase } from 'common/components/CapitalLetter/CapitalLetterWithoutPrepositions';
import dayjs from 'dayjs';
import Delete from 'BidGrid/components/modalDelete/Delete';
import noFoundImg from "../assets/images/empty.png"
import { useSelector } from 'react-redux';

const { DirectoryTree } = Tree;

const initialState = {
	parent_id: "",
	folder_name: "",
	document_title: "",
	document_file_name: [],

}
const uploadButton = (
	<button
		style={{
			border: 0,
			background: 'none',
		}}
		type="button"
	>
		<PlusOutlined />
		<div
			style={{
				marginTop: 8,
			}}
		>
			Upload
		</div>


	</button>
);
const sortingOption = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'a-z',
		label: 'A-Z',
	},
	{
		value: 'z-a',
		label: 'Z-A',
	},
	{
		value: 'newest',
		label: 'Newest',
	},
	{
		value: 'oldest',
		label: 'Oldest',
	},
	{
		value: 'file only',
		label: 'File Only',
	},
	{
		value: 'folder only',
		label: 'Folder Only',
	},
]

function Briefcase() {
	const { userBidInfo } = useSelector((state) => state.userDetails)
	const [basicModal, setBasicModal] = useState(false)
	const [basicModaLinner, setBasicModaLinner] = useState(false)
	const [uploadDocModal, setUploadDocModal] = useState(false);
	const [uploadFolderModal, setUploadFolderModal] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showInnerFolder, setShowInnerFolder] = useState(false)
	const [showCloseZoomIcon, setShowCloseZoomIcon] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false)
	const [esc, setEsc] = useState(false)
	const [spinLoader, setSpinLoader] = useState(true);
	const [showBread, setShowBread] = useState(true)
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [selectedNodeData, setSelectedNodeData] = useState(null);
	const [searchedNodeData, setSearchedNodeData] = useState([])
	const [resetNodeData, setResetNodeData] = useState({})
	const [createFolderFile, setCreateFolderFile] = useState(initialState);
	const [treeDataRight, setTreeDataRight] = useState([])
	const [treeDataLeft, setTreeDataLeft] = useState([]);
	const [combinedResponse, setCombinedResponse] = useState([])
	const [sort, setSort] = useState(null)
	const [search, setSearch] = useState('')
	const [outerFolder] = Form.useForm()
	const [innerFolder] = Form.useForm()
	const [fileForm] = Form.useForm()
	const [breadcrumb, setBreadcrumb] = useState([])
	const notify = (error) => toast.error(error);
	const notifySuccess = (msg) => toast.success(msg);
	const location = useLocation();
	const val = location?.pathname;
	const str = val?.replace('/', '')
	const [Permission, setPermission] = useState('');
	const [modalData, setModalData] = useState({})
	const [fileName, setFileName] = useState('')
	const [loadings, setLoadings] = useState({
		loadingFolder: false,
		loadingFile: false,
	});
	const deleteLabel = (Array.isArray(Permission) && (Permission[0]?.is_delete === '0' || userBidInfo?.permanent_sys_adm === '1')) ? 'Delete' : null;

	const downloadLabel = (Array.isArray(Permission) && (Permission[0]?.is_download === '0' || userBidInfo?.permanent_sys_adm === '1')) ? 'Download' : null;

	const priviewLabel = (Array.isArray(Permission) && (Permission[0]?.is_priview === '0' || userBidInfo?.permanent_sys_adm === '1')) ? 'Preview' : null;

	const handleLoading = (key, value) => {
		setLoadings(prevLoadings => ({
			...prevLoadings,
			[key]: value
		}));
	};

	const creatBriefFolder = async (values, parentId) => {
		handleLoading('loadingFolder', true);
		const formData = new URLSearchParams();
		formData.append("folder_name", values?.folder_name)
		formData.append("parent_id", parentId)
		try {
			const response = await briefcase?.briefFolder(formData)
			if (response?.data?.status === '1') {
				fetchBriefFolderList()
				setBasicModal(false);
				setBasicModaLinner(false);
				outerFolder.resetFields();
				innerFolder.resetFields();
				handleLoading('loadingFolder', false);
				notifySuccess('Folder Added Successfully')
			} else {
				notify('Folder Already Exist')
				handleLoading('loadingFolder', false);
			}
		} catch (error) {
			console.log("error", error);
			handleLoading('loadingFolder', false);
		}
	}

	const createFile = async (value) => {
		handleLoading('loadingFile', true);
		const formData = new FormData();
		formData.append("document_title", value.document_title)
		formData.append("folder_id", selectedNodeData?.key)
		formData.append("share_with", 1)
		createFolderFile?.document_file_name?.forEach((file, index) => {
			console.log(file, 'file')
			formData.append('document_file_name', file.originFileObj);
		});
		try {
			const response = await briefcase?.briefDocAdd(formData)
			if (response?.data?.status === '1') {
				fetchBriefFolderList()
				handleLoading('loadingFile', false);
				setCreateFolderFile(pre => ({ ...pre, document_file_name: [] }))
				setUploadDocModal(false)
				fileForm.resetFields();
				notifySuccess('File Added Successfully')
			} else {
				notify('File Already Exist')
				handleLoading('loadingFile', false);
			}
		} catch (error) {
			handleClose()
			console.log("error", error);
			handleLoading('loadingFile', false);
		}
	}


	const TransformLeftNodes = (apiData) => {
		const transformedData = [];
		const visited = {};

		const buildTree = (parentId) => {
			if (visited[parentId]) {
				return [];
			}
			visited[parentId] = true;
			const children = apiData.filter(folder => folder.parent_id === parentId);
			const nodes = children.map(child => {
				if (child.hasOwnProperty('document_file_name')) {
					return null;
				} else {
					const node = {
						title: capitalizeExceptPrepositionsAndLowerCase(child.folder_name),
						key: child.id,
						type: child.type,
						icon: <img src={fileclose} width={17} alt='' />,
					};
					const nestedChildren = buildTree(child.id);
					if (nestedChildren.length > 0) {
						node.children = nestedChildren;
					}
					return node;
				}
			}).filter(Boolean);
			return nodes;
		};

		transformedData.push(...buildTree(0));

		return transformedData;
	};

	const TransformRightNodes = (apiData) => {
		const transformedData = [];
		const visited = {};

		const buildTree = (parentId) => {
			if (visited[parentId]) {
				return [];
			}
			visited[parentId] = true;
			const children = apiData?.filter(folder => folder.parent_id === parentId);
			if (!children || children.length === 0) {
				return [];
			}
			return children.map(child => {
				const node = {
					title: capitalizeExceptPrepositionsAndLowerCase(child.folder_name),
					document_file_name: child.document_file_name,
					document_file_path: child.document_file_path,
					parent_id: child.parent_id,
					key: child.id,
					type: child.type,
					created_at: dayjs(child?.created_at).format('YYYY-MM-DD HH:mm:ss'),
					document_title: child?.document_title,
					icon: <img src={fileclose} width={17} alt='' />,
				};
				const nestedChildren = buildTree(child.id);
				if (nestedChildren.length > 0) {
					node.children = nestedChildren;
				}
				return node;
			});
		};

		transformedData.push(...buildTree(0));

		return transformedData;
	};

	const findSelectedNode = (apiData, breadKey) => {
		const buildTreeNode = (node) => {
			const { folder_name, document_file_name, document_file_path, parent_id, id, type, document_title, created_at } = node;
			return {
				title: folder_name,
				document_file_name: document_file_name,
				document_file_path: document_file_path,
				parent_id: parent_id,
				key: id,
				type: type,
				document_title: document_title,
				created_at: dayjs(created_at).format('YYYY-MM-DD HH:mm:ss'),
				icon: <img src={fileclose} width={17} alt='' />,
				children: []
			};
		};

		const buildTree = (parentId, visited) => {
			if (visited[parentId]) {
				return [];
			}
			visited[parentId] = true;

			const children = apiData.filter(child => child.parent_id === parentId);
			if (!children || children.length === 0) {
				return [];
			}

			return children.map(child => {
				const node = buildTreeNode(child);
				node.children = buildTree(child.id, visited);
				return node;
			});
		};
		const visited = {};
		let selectedNode = {}

		if (breadKey) {
			selectedNode = apiData.find(node => node.id === breadKey);
		} else {
			selectedNode = apiData.find(node => node.id === selectedNodeData?.key);
		};

		// if (isSort) {
		//     return selectedNode
		// }

		let fetchNodeList = [];
		if (selectedNode) {
			fetchNodeList.push(buildTreeNode(selectedNode));
			fetchNodeList[0].children = buildTree(selectedNode.id, visited);
		}

		return fetchNodeList;
	};


	const fetchBriefFolderList = async () => {
		setSpinLoader(true);
		try {
			const response = await briefcase?.briefFolderList();
			const responseFile = await briefcase?.briefDocumentList();

			if (response?.data?.status === "1" || responseFile?.data?.status === '1') {

				const apiresponses = [...response?.data?.data, ...responseFile?.data?.data]
				const transformedData = await TransformLeftNodes(apiresponses);
				const transformedDataNew = await TransformRightNodes(apiresponses);
				const selectedNodeDatas = await findSelectedNode(apiresponses, null)
				setCombinedResponse(apiresponses)
				setSelectedNodeData(selectedNodeDatas[0])
				setTreeDataLeft(transformedData);
				setTreeDataRight(transformedDataNew)
				setSpinLoader(false);

			}
		} catch (error) {
			console.log("error", error);
			setSpinLoader(false)
		}
	};

	const fetchBriefPermission = async () => {
		setSpinLoader(true);
		try {
			const formData = new URLSearchParams();
			formData.append("event_name", "is_view")
			formData.append("user_id", userBidInfo?.id)
			const response = await briefcase?.briefPermissionList(formData);
			if (response?.data?.status === "1") {
				setPermission(response?.data?.data)
			}
		} catch (error) {
			console.log("error", error);
			setSpinLoader(false)
		}
	};

	useEffect(() => {
		fetchBriefFolderList()
		fetchBriefPermission()
	}, [])

	const onExpand = (keys, info) => {

		setExpandedKeys(keys);
	};



	const handleBreadcrumb = (nodeData, apiresponse) => {
		let breadcrumbItems = [];
		if (nodeData.parent_id === 0) {
			return breadcrumbItems;
		}
		const traverse = (nodes, parentId) => {
			for (const node of nodes) {
				if (node.id === parentId) {
					breadcrumbItems.push(node.folder_name);
					traverse(apiresponse, node.parent_id);
					break;
				}
			}
		};
		traverse(apiresponse, nodeData?.parent_id);
		return breadcrumbItems.reverse();
	};
	const onSelect = (selectedKeys, info) => {
		setShowBread(true)
		const selectedNode = findNodeByKey(treeDataRight, selectedKeys[0]);
		setSelectedNodeData(selectedNode);
		setResetNodeData(selectedNode)
		const breadcrumbs = handleBreadcrumb(selectedNode, combinedResponse)
		console.log(breadcrumbs)
		setBreadcrumb(breadcrumbs)
		setShowInnerFolder(true);
	};


	const findNodeByKey = (data, key) => {
		for (let i = 0; i < data.length; i++) {
			const node = data[i];
			if (node.key === key) {
				return node;
			}
			if (node.children) {
				const foundNode = findNodeByKey(node.children, key);
				if (foundNode) {
					return foundNode;
				}
			}
		}
		return null;
	};

	const fileType = (node) => {
		switch ((node?.title)?.toLowerCase()) {
			case '.png':
			case '.jpg':
			case '.jpeg':
				return fileImgImage;
			case '.pdf':
				return filePDFImage;
			case '.pptx':
			case '.ppt':
				return filePPTImage;
			case '.csv':
				return fileCSVImage;
			case '.txt':
				return fileTextImage;
			case '.xlsx':
			case '.xls':
				return xlsImg;
			case '.doc':
			case '.docx':
				return fileDocImage;
			case '.mp4':
			case '.mov':
			case '.mpeg-2':
			case '.webm':
			case '.mkv':
				return fileVideoImage;
			default:
				return universalImg;
		}
	}

	let docImgBaseUrl = "https://doc.mystorepedia.com/"

	const fileDelete = async (val) => {

		const formData = new URLSearchParams()
		formData.append('file_id', val?.key)
		try {
			const response = await briefcase.briefFileDelete(formData)
			if (response?.data?.status === '1') {
				notifySuccess('File Deleted Successfully')
				await fetchBriefFolderList()

			} else {
				notify(response?.response?.data?.message)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const folderDelete = async (val) => {
		const formData = new URLSearchParams()
		formData.append('folder_id', val?.key)
		try {
			const response = await briefcase.briefFolderDelete(formData)
			if (response?.data?.status === '1') {
				notifySuccess('Folder Deleted Successfully')
				await fetchBriefFolderList()
			} else {
				notify(response?.response?.data?.message)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const innerFolderDelete = async (folderId) => {
		const formData = new URLSearchParams()
		formData.append('folder_id', folderId)

		try {
			const response = await briefcase.briefFolderDelete(formData)
			if (response?.data?.status === '1') {
				notifySuccess('Folder Deleted Successfully')
				await fetchBriefFolderList()
			} else {
				await fetchBriefFolderList()
				notify(response?.response?.data?.message)
			}
		} catch (error) {
			await fetchBriefFolderList()
			console.log(error)
		}

	}

	const checkParentKeyExist = (nodeData) => {

		let expandedKeyss = [...expandedKeys, nodeData.key];

		const traverse = (resp, parentId) => {
			for (const node of resp) {
				if (node.id === parentId) {
					if (!expandedKeyss?.includes(node.id)) {
						expandedKeyss.push(node.id);
					}
					traverse(combinedResponse, node.parent_id);
					break;
				}
			}
		};
		traverse(combinedResponse, nodeData?.parent_id);
		return expandedKeyss
	}
	const handleDeleteModal = (items, value) => {
		setFileName(value)
		setModalData(items)
		setDeleteModal(true)
	}
	const IB_Item = ({ node }) => {
		const [isModalPreview, setIsModalPreview] = useState(false);

		const onItemClick = () => {
			if (node.document_file_name) {
				return
			}
			const breadcrumbs = handleBreadcrumb(node, combinedResponse)
			setBreadcrumb(breadcrumbs)
			const selectedNodeDatas = findSelectedNode(combinedResponse, node?.key)
			setSelectedNodeData(selectedNodeDatas[0]);
			setResetNodeData(selectedNodeDatas[0])
			setShowInnerFolder(true);
			const expandkey = checkParentKeyExist(node)
			setExpandedKeys(expandkey)
		};



		const onDocClick = (node) => {
			if (['.png', '.jpeg', '.jpg'].includes(node.title.toLowerCase())) {
				// Open image in modal preview
				showModalPreview(node);
			} else if (node?.title == ".xlsx" || node?.title == ".xls" || node?.title == ".pptx" || node?.title == ".ppt" || node?.title == ".csv" || node.title == ".doc" || node.title == ".docx") {
				const url = `https://view.officeapps.live.com/op/view.aspx?src=${docImgBaseUrl}/${node.document_file_path}/${node.document_file_name}`;
				window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=800,height=800");
			} else if (node.title == ".txt") {
				const url = `${docImgBaseUrl}${node.document_file_path}/${node.document_file_name}`
				window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=800,height=800");
			}
			else {
				const url = `${docImgBaseUrl}${node.document_file_path}/${node.document_file_name}`
				window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=800,height=800");

			}
		}

		const showModalPreview = () => {
			setIsModalPreview(true);
		};

		const handleCancelPreview = () => {
			setIsModalPreview(false);
		};

		const openInNewTab = (url) => {
			const newWindow = window.open(url, '_blank');
			if (newWindow) newWindow.opener = null;
		};


		return (

			<>
				{node?.document_file_name ?
					<Tooltip title={node?.document_title}>
						<Dropdown
							menu={{
								items: [
									{
										label: priviewLabel,
										key: '1',
										onClick: () => onDocClick(node)
									},
									{
										label: downloadLabel,
										key: '2',
										onClick: () => documentFileDownload(node),
									},
									{
										label: deleteLabel,
										key: '3',
										onClick: () => handleDeleteModal(node, 'image')
									},
								],
							}}
							trigger={['contextMenu']}
							getPopupContainer={() => document.getElementById('zoom')}
						>
							<div className="ib_item" contenteditable="true" onKeyDown={(e) => e.preventDefault()}>
								{
									['.png', '.jpeg', '.jpg', ""].includes(node.title.toLowerCase()) ? (
										<div className="imgCase" onDoubleClick={showModalPreview} >
											<img width={55} src={`${docImgBaseUrl}${node.document_file_path}/${node.document_file_name}`} alt='img' />
										</div>
									) : (
										<img src={fileType(node)} width={50} onDoubleClick={() => openInNewTab(`${docImgBaseUrl}/${node.document_file_path}/${node.document_file_name}`)} alt='img' />
									)
								}
								<div className="fileName_text">{node?.document_title}</div>
							</div >
						</Dropdown >
					</Tooltip >
					:
					<Tooltip title={node?.title}>
						<Dropdown
							menu={{
								items: [

									{
										label: 'Delete',
										key: '1',
										onClick: () => folderDelete(node)
									},
								],
							}}
							trigger={['contextMenu']}
						>
							<div className="ib_item" onDoubleClick={onItemClick} contenteditable="true" onKeyDown={(e) => e.preventDefault()}>
								<img src={folderIcon} width={55} alt='' />
								<div className="fileName_text">{node.title}</div>
							</div>
						</Dropdown>
					</Tooltip>


				}
				{
					isModalPreview &&
					<Modal
						title={node.document_file_name}
						visible={isModalPreview}
						onCancel={handleCancelPreview}
						footer={null}
						getContainer={() => document.getElementById('zoom')}
					>
						<img src={`${docImgBaseUrl}${node.document_file_path}/${node.document_file_name}`} alt='' style={{ width: '100%', height: 'auto' }} />
					</Modal>
				}
			</>
		);
	};

	// const documentBlobReq = async (doc_name, apiUrl) => {
	// 	const fullUrl = window.location.href;
	// 	const urlObject = new URL(fullUrl);
	// 	const protocol = urlObject.protocol;
	// 	const hostname = urlObject.host;
	// 	const domain = `${protocol}//${hostname}`;
	// 	const proxyUrl = `${domain}/proxy?url=${encodeURIComponent(apiUrl)}`;
	// 	const response = await fetch(proxyUrl)
	// 	const blobData = await response?.blob()
	// 	const fileURL = window.URL.createObjectURL(blobData);
	// 	let alink = document.createElement("a");
	// 	alink.href = fileURL;
	// 	alink.download = doc_name;
	// 	alink.click();
	// }
	// const documentFileDownload = (item) => {
	// 	const docAuth = 'https://demo4.growthgrids.com/'
	// 	const apiUrl = `${docAuth}${item?.document_file_path}/${item?.document_file_name}`;
	// 	documentBlobReq(item?.document_file_name, apiUrl)
	// }

	const documentBlobReq = async (doc_name, apiUrl) => {
		try {
			const response = await fetch(apiUrl);

			if (!response.ok) {
				throw new Error(`Failed to fetch image: ${response.statusText}`);
			}
			if (!response.headers.get("Content-Type").includes("image")) {
				throw new Error("Response is not an image");
			}

			const blobData = await response.blob();
			const fileURL = URL.createObjectURL(blobData);

			const alink = document.createElement("a");
			alink.href = fileURL;
			alink.download = doc_name;
			alink.click();

			URL.revokeObjectURL(fileURL);
		} catch (error) {
			console.error("Error downloading document:", error);
		}
	};

	const documentFileDownload = (item) => {
		const docAuth = 'https://doc.mystorepedia.com/';
		const apiUrl = `${docAuth}${item?.document_file_path}/${item?.document_file_name}`;
		documentBlobReq(item?.document_file_name, apiUrl);
	};



	function openFullscreen() {
		let elem = document.getElementById('zoom');

		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) {
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			elem = window.top.document.body;
			elem.msRequestFullscreen();
		}
		setShowCloseZoomIcon(!showCloseZoomIcon)
		setEsc(prev => !prev)

	}


	function closeFullscreen() {
		if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}

	const handleFullscreenChange = () => {
		if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
			handleEscapeKeyPress({ key: 'Escape' });

		}
	};

	const handleEscapeKeyPress = (event) => {
		if (event?.key === 'Escape') {
			setEsc(true)
			setShowCloseZoomIcon((prevShowCloseZoomIcon) => !prevShowCloseZoomIcon)
		}

	};

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullscreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
	}, [esc]);


	//sorting
	const handleSorting = (e) => {
		setSort(e)
		let findNode = {}
		if (search) {
			findNode = searchedNodeData
		} else {
			findNode = resetNodeData
		}
		if (e === 'file only') {
			if (findNode) {
				const filteredChildren = findNode?.children?.filter(item => item?.title?.includes('.'));
				const updatedFindNode = {
					...findNode,
					children: filteredChildren
				};

				setSelectedNodeData(updatedFindNode)

			}
		} else if (e === 'folder only') {
			if (findNode) {
				const filteredChildren = findNode?.children?.filter(item => !item?.title?.includes('.'));

				const updatedFindNode = {
					...findNode,
					children: filteredChildren
				};

				setSelectedNodeData(updatedFindNode)

			}
		} else if (e === 'oldest') {
			if (findNode) {
				const sortedArray = findNode?.children?.reduce((acc, obj) => {
					let inserted = false;
					for (let i = 0; i < acc?.length; i++) {
						if (new Date(obj?.created_at) < new Date(acc[i]?.created_at)) {
							acc.splice(i, 0, obj);
							inserted = true;
							break;
						}
					}
					if (!inserted) {
						acc.push(obj);
					}
					return acc;
				}, []);
				const updatedFindNode = {
					...findNode,
					children: sortedArray
				};

				setSelectedNodeData(updatedFindNode)
			}


		} else if (e === 'newest') {
			if (findNode) {
				const sortedArray = findNode?.children?.reduce((acc, obj) => {
					let inserted = false;
					for (let i = 0; i < acc?.length; i++) {
						if (new Date(obj?.created_at) > new Date(acc[i]?.created_at)) {
							acc.splice(i, 0, obj);
							inserted = true;
							break;
						}
					}
					if (!inserted) {
						acc.push(obj);
					}
					return acc;
				}, []);
				const updatedFindNode = {
					...findNode,
					children: sortedArray
				};
				setSelectedNodeData(updatedFindNode)

			}
		} else if (e === 'a-z') {
			if (findNode) {
				const sortedData = findNode?.children?.sort((a, b) => {
					const titleA = a?.title?.startsWith('.') ? a?.document_title : a?.title;
					const titleB = b?.title?.startsWith('.') ? b?.document_title : b?.title;
					return titleA.localeCompare(titleB);
				});
				const updatedFindNode = {
					...findNode,
					children: sortedData
				};
				setSelectedNodeData(updatedFindNode)

			}
		} else if (e === 'z-a') {
			if (findNode) {
				const sortedData = findNode?.children?.sort((a, b) => {
					const titleA = a?.title?.startsWith('.') ? a?.document_title : a?.title;
					const titleB = b?.title?.startsWith('.') ? b?.document_title : b?.title;
					return titleB.localeCompare(titleA);
				});

				const updatedFindNode = {
					...findNode,
					children: sortedData
				};

				setSelectedNodeData(updatedFindNode)
			}
		}

		else {
			setSelectedNodeData(findNode)
		}
	}

	const getParentToChildSearch = async (isReset) => {
		if (!search && !isReset) {
			return
		}

		if (!isReset) {
			setSpinLoader(true)
			const buildTreeNode = (node) => {
				const { folder_name, document_file_name, document_file_path, parent_id, id, type, document_title, created_at } = node;
				return {
					title: folder_name,
					document_file_name: document_file_name,
					document_file_path: document_file_path,
					parent_id: parent_id,
					key: id,
					type: type,
					document_title: document_title,
					created_at: dayjs(created_at).format('YYYY-MM-DD HH:mm:ss'),
					icon: <img src={fileclose} width={17} alt='' />,
					children: []
				};
			};
			let childrens = []
			// Define a function to recursively build tree
			const buildTree = (parentId, visited) => {
				if (visited[parentId]) {
					return []; // Terminate recursion if already visited
				}
				visited[parentId] = true; // Mark current node as visited

				const children = combinedResponse.filter(child => child.parent_id === parentId);
				if (!children || children.length === 0) {
					return []; // Terminate recursion if no children found
				}
				return children?.map(child => {
					const node = buildTreeNode(child);
					childrens.push(node)
					buildTree(child.id, visited)
					return childrens;
				});
			};
			const visited = {};
			// Fetch selected node and its children
			let selectedNode = combinedResponse.find(node => node.id === resetNodeData?.key);
			let fetchNodeList = [];

			if (selectedNode) {
				fetchNodeList.push(buildTreeNode(selectedNode));
				fetchNodeList[0].children = buildTree(selectedNode.id, visited)[0];
				fetchNodeList[0].children = await fetchNodeList[0].children.filter((item, index) => {
					const titleA = item?.title?.startsWith('.') ? item?.document_title : item?.title;
					const data = !isReset ? titleA?.toLowerCase().includes(search?.toLowerCase()) : titleA?.toLowerCase()
					return data
				})
				setSearchedNodeData(fetchNodeList[0])
				setSelectedNodeData(fetchNodeList[0])
			}
			setSpinLoader(false)
		} else {
			setSearchedNodeData(resetNodeData)
			setSelectedNodeData(resetNodeData)
		}

	}

	const sortingSearch = (e) => {
		setSearch(e?.target?.value)
	}

	// sorting reset 
	const closeAllFunc = () => {
		setSelectedNodeData(null);
		setExpandedKeys([]);

		setShowBread(false)
		setBreadcrumb([])
	}


	const sortingReset = () => {
		getParentToChildSearch(true)
		setSearch('')
		setSort(null)
	}

	const breadcrumbNavigation = (val) => {
		const findData = combinedResponse?.find((value) => value?.folder_name === val)

		const selectedNodeDatas = findSelectedNode(combinedResponse, findData?.id)
		setSelectedNodeData(selectedNodeDatas[0])
		const breadcrumbs = handleBreadcrumb(findData, combinedResponse)
		console.log(breadcrumbs)
		setBreadcrumb(breadcrumbs)
	}

	const handleBack = () => {
		if (breadcrumb?.length > 1) {
			const backData = breadcrumb?.splice(breadcrumb?.length - 1, 1);
			const convertString = backData?.join(' ');
			breadcrumbNavigation(convertString);
		} else if (breadcrumb?.length === 1) {
			breadcrumbNavigation(breadcrumb[0]);
		} else {
			return;
		}
	}

	useEffect(() => {
		if (!selectedNodeData) {
			const generalDocNode = findNodeByKey(treeDataLeft, '0-0');
			setSelectedNodeData(generalDocNode);
			setShowInnerFolder(true);
		}
	}, [treeDataLeft, selectedNodeData]);

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setBasicModal(false)
		setBasicModaLinner(false)
	};

	const handleClose = () => {
		setUploadDocModal(false)
		setUploadFolderModal(false)
	}

	const handleChange = (name, value) => setCreateFolderFile({ ...createFolderFile, [name]: value })


	const handleTrimStart = (name, e) => {
		const trimmedValue = e.target.value.trimStart();
		outerFolder.setFieldsValue({ [name]: trimmedValue });
		innerFolder.setFieldsValue({ [name]: trimmedValue });
		fileForm.setFieldsValue({ [name]: trimmedValue });
	};

	return (
		<>
			<Breadcrumb data={str} />
			{/* <div className="bd_document_warpper">
				{
					!spinLoader ?
						<div className="bd_doc_folder" id='zoom'>

							<div className="bd_doc_folder">
								<div className="nt_head" >
									<div className="d-flex">
										<div className="goBack_btn" onClick={() => handleBack()}>
											<LeftTwo theme="outline" size="24" fill="#98a1b3" strokeWidth={3} strokeLinecap="butt" />
										</div>
										{
											Permission[0]?.is_add === "0" ? (
                        <div className="newFolder_btn" onClick={(() => { setBasicModal(true); })}
												>
													<FolderPlus theme="outline" size="24" fill="#4d4d4d" strokeWidth={3} strokeLinecap="butt"
													/>
													New Folder
												</div>
											) : 
												""
										}

										<div className="closeFolder" onClick={() => closeAllFunc()}>
											<img src={collapseImg} width={18} alt='img' />
											Close all
										</div>


										{
											Permission[0]?.is_delete === '0' ? (
												<button className='deleteFolder' onClick={() => handleDeleteModal(selectedNodeData, 'folder')}>
													<DeleteOne theme="outline" size="20" fill="#4d4d4d" strokeWidth={2} strokeLinecap="butt"
													/>
													Delete Folder
												</button>
											) : ""
										}
									</div>
									<div className="nth_right">
										<div className="search_docItem">
											<Input
												type='text'
												// onChange={(e) => setSearch(e.target.value)}
												onChange={sortingSearch}
												value={search}
												placeholder='Search here'
												style={{ width: '280px' }}
											/>
											<button onClick={() => getParentToChildSearch(false)}><Search theme="outline" size="22" fill="#98a1b3" strokeWidth={3} strokeLinecap="butt" /></button>
										</div>

										<Select
											style={{
												minWidth: 100,
											}}
											placeholder='Sort By'
											onChange={handleSorting}
											// onChange={handleChange}
											options={sortingOption}
											value={sort}
											getContainer={() => document.getElementById('zoom')}
											getPopupContainer={() => document.getElementById('zoom')}

										/>
										<button className="BG_ghostButton" onClick={sortingReset}> Reset</button>
										{showCloseZoomIcon ? <button className='zoomInOut' onClick={closeFullscreen} ><img src={zoomOut} alt='img' /></button>
											: <button className="zoomInOut" onClick={openFullscreen}><img src={zoomIn} alt='img' /></button>
										}
									</div>
								</div>
								<Row gutter={0}>
									<Col span={5}>
										<div className="nestTree_wrap">

											<DirectoryTree
												selectedKeys={[selectedNodeData?.key]}
												// setIconOpen={setIconOpen}
												multiple
												onSelect={onSelect}
												treeData={treeDataLeft}
												onExpand={onExpand}
												expandedKeys={expandedKeys}
											/>
										</div>
									</Col>
									<Col span={19}>

										{selectedNodeData?.key && <div className="innerFolder" style={showInnerFolder ? { display: "block" } : { display: "none" }}>
											<div className="inner_head">
												<div className="breadcrumb_wrap">
													{breadcrumb?.map((val) => {
														return (
															<div className="bw_item" onClick={() => breadcrumbNavigation(val)}>

																{val}

																<Right theme="outline" size="18" fill="#98a1b3" strokeWidth={3} strokeLinecap="butt" />

															</div>
														)
													})}
													<div className="bw_item_active">{selectedNodeData?.title}</div>

												</div>
												<div className="d-flex">
                          {
                            Permission[0]?.is_add === '0' && userBidInfo?.permanent_sys_adm === '1' ? (
                              <>
                              <button className='BG_mainButton' onClick={(() => setBasicModaLinner(true))}>  <SettingOutlined />
                              Create Folder</button>
                              </>
                            ) : ""
                          }

{
                            Permission[0]?.is_update === '0' ? (
                              <>
                              	<button className="BG_mainButton uploadButton" onClick={(() => setUploadDocModal(true))}>  <UploadOutlined />
                                Upload File</button>
                              </>
                            ) : ""
                          }

												</div>
											</div>
											{selectedNodeData && selectedNodeData.children?.length > 0 ? (
												<div className="inner_body">
													<div className="grid__wrapper">
														{selectedNodeData && selectedNodeData?.children?.filter(item => item?.title !== '.db')?.map((child) => (
															<IB_Item key={child?.key} node={child} />
														))}
													</div>
												</div>
											) : <div className="noFound_wrapper">
												<img src={noFoundImg} alt='img' />
												Folder is empty
											</div>}
										</div>}

									</Col>
								</Row>
							</div >
						</div >
						:
						<div className="bd_doc_folder treeSpinner" >  <Spin size="large" />
						</div>
				}




				<Modal title="Create Folder" open={basicModal} className="bd_model_main shareModal"
					onCancel={() => { handleCancel(); outerFolder.resetFields(); }}
					footer={null}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form name="validateOnly" form={outerFolder} layout="vertical" autoComplete="off" onFinish={(val) => creatBriefFolder(val, 0)}>
						<div className='bd_model_input'>
							<Form.Item label="Folder Name" name="folder_name" rules={[{ required: true, message: 'Folder name is required' }]}>
								<Input placeholder='Enter here' onChange={(e) => handleTrimStart('folder_name', e)} />
							</Form.Item>
						</div>
						<Flex justify='flex-end' align='center'>
							<Button key="back" className='BG_ghostButton' onClick={() => outerFolder.resetFields()}>
								Reset
							</Button>
							<Button key="submit" className='BG_mainButton' style={{ marginLeft: '20px' }} type="primary" htmlType="submit" loading={loadings?.loadingFolder} disabled={loadings?.loadingFolder}>
								Submit
							</Button>
						</Flex>
					</Form>
				</Modal >

				<Modal title="Share" className='bd_model_main shareModal' open={isModalOpen} id='zoom'
					width={700}
					onOk={handleOk} onCancel={handleCancel}
					footer={[
						<>
							<button key="back" className="BG_ghostButton">Reset</button>
							<button key="submit" className="BG_mainButton">Submit</button>
						</>
					]}
					getContainer={() => document.getElementById('zoom')}


				>
					<Form name="validateOnly" layout="vertical" autoComplete="off">
						<div className='bd_model_input'>
							<Row gutter={30}>
							</Row>
						</div>
					</Form>
				</Modal>
				< Modal title="Create Folder" open={basicModaLinner} className="bd_model_main shareModal"
					onCancel={() => { handleCancel(); innerFolder.resetFields() }}
					footer={null}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form name="validateOnly" layout="vertical" autoComplete="off" form={innerFolder} onFinish={(val) => creatBriefFolder(val, selectedNodeData?.key)}>
						<div className='bd_model_input'>
							<Form.Item label="Folder Name" name="folder_name" rules={[{ required: true, message: 'Folder name is required' }]}>
								<Input placeholder='Enter here' onChange={(e) => handleTrimStart('folder_name', e)} />
							</Form.Item>
						</div>

						<Flex justify='flex-end' align='center'>
							<Button key="back" className='BG_ghostButton' onClick={() => innerFolder.resetFields()}>
								Reset
							</Button>
							<Button key="submit" className='BG_mainButton' style={{ marginLeft: '20px' }} type="primary" htmlType="submit" loading={loadings?.loadingFolder} disabled={loadings?.loadingFolder}>
								Submit
							</Button>
						</Flex>
					</Form>
				</Modal >
				< Modal title="Upload Document" open={uploadDocModal} className="bd_model_main uploadDoc_modal"
					onCancel={() => {
						handleClose(); fileForm.resetFields(); setCreateFolderFile(pre => ({ ...pre, document_file_name: [] }))
					}}
					footer={null}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form
						layout='vertical'
						name="control-ref"
						form={fileForm}
						onFinish={createFile}
					>
						<Form.Item
							name="document_file_name"
							label="Document File"
							rules={[{ required: true, message: 'File is required' }]}
						>

							<Upload
								action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
								listType="picture-card"
								// maxCount={1}
								multiple
								// onPreview={handlePreview}
								showUploadList={{ showPreviewIcon: false }}
								fileList={createFolderFile?.document_file_name}
								onChange={(info) => {
									const fileList = info?.fileList?.map((file) => ({
										uid: file?.uid,
										name: file?.name,
										status: 'done',
										originFileObj: file?.originFileObj,
									}));
									handleChange('document_file_name', fileList);
									// if (fileList.length > 0) {
									const selectedFile = fileList[0]?.originFileObj;
									if (selectedFile && fileList.length == 1) {
										const fileName = selectedFile.name;
										fileForm.setFieldsValue({ document_title: fileName });
									}


								}}


							>

								{createFolderFile?.document_file_name?.length >= 20
									? null
									: uploadButton}
							</Upload>



						</Form.Item>


						<Form.Item
							name="document_title"
							label="Document Title"
							className='doc_inp'
							rules={[
								{
									required: true,
									message: 'Document name is required'
								},
							]}
						>
							<Input placeholder='Enter here' onChange={(e) => handleTrimStart('document_title', e)} />
						</Form.Item>


						<div className="btn_flex">
							<Button className="BG_ghostButton" onClick={() => { fileForm.resetFields(); setCreateFolderFile(pre => ({ ...pre, document_file_name: [] })) }}>
								Reset
							</Button>
							<Button className="BG_mainButton" key="submit" type="primary" htmlType="submit" loading={loadings?.loadingFile} disabled={loadings?.loadingFile}>
								Upload
							</Button>
						</div>

					</Form>
				</Modal >



			</div> */}

			<div className="bd_document_warpper">
				{
					!spinLoader ?
						<div className="bd_doc_folder" id='zoom'>
							<div className="bd_doc_folder">
								<div className="nt_head">
									<div className="d-flex">
										<div className="goBack_btn" onClick={() => handleBack()}>
											<LeftTwo theme="outline" size="24" fill="#98a1b3" strokeWidth={3} strokeLinecap="butt" />
										</div>
										{
											(Permission[0]?.is_add === "0" || userBidInfo?.permanent_sys_adm === '1') && (
												<div className="newFolder_btn" onClick={() => { setBasicModal(true); }}>
													<FolderPlus theme="outline" size="24" fill="#4d4d4d" strokeWidth={3} strokeLinecap="butt" />
													New Folder
												</div>
											)
										}
										<div className="closeFolder" onClick={() => closeAllFunc()}>
											<img src={collapseImg} width={18} alt='img' />
											Close all
										</div>
										{
											(Permission[0]?.is_delete === '0' || userBidInfo?.permanent_sys_adm === '1') && (
												<button className='deleteFolder' onClick={() => handleDeleteModal(selectedNodeData, 'folder')}>
													<DeleteOne theme="outline" size="20" fill="#4d4d4d" strokeWidth={2} strokeLinecap="butt" />
													Delete Folder
												</button>
											)
										}
									</div>
									<div className="nth_right">
										<div className="search_docItem">
											<Input
												type='text'
												onChange={sortingSearch}
												value={search}
												placeholder='Search here'
												style={{ width: '280px' }}
											/>
											<button onClick={() => getParentToChildSearch(false)}><Search theme="outline" size="22" fill="#98a1b3" strokeWidth={3} strokeLinecap="butt" /></button>
										</div>
										<Select
											style={{ minWidth: 100 }}
											placeholder='Sort By'
											onChange={handleSorting}
											options={sortingOption}
											value={sort}
											getContainer={() => document.getElementById('zoom')}
											getPopupContainer={() => document.getElementById('zoom')}
										/>
										<button className="BG_ghostButton" onClick={sortingReset}> Reset</button>
										{showCloseZoomIcon ?
											<button className='zoomInOut' onClick={closeFullscreen}>
												<img src={zoomOut} alt='img' />
											</button>
											:
											<button className="zoomInOut" onClick={openFullscreen}>
												<img src={zoomIn} alt='img' />
											</button>
										}
									</div>
								</div>
								<Row gutter={0}>
									<Col span={5}>
										<div className="nestTree_wrap">
											<DirectoryTree
												selectedKeys={[selectedNodeData?.key]}
												multiple
												onSelect={onSelect}
												treeData={treeDataLeft}
												onExpand={onExpand}
												expandedKeys={expandedKeys}
											/>
										</div>
									</Col>
									<Col span={19}>
										{selectedNodeData?.key && (
											<div className="innerFolder" style={showInnerFolder ? { display: "block" } : { display: "none" }}>
												<div className="inner_head">
													<div className="breadcrumb_wrap">
														{breadcrumb?.map((val) => (
															<div className="bw_item" onClick={() => breadcrumbNavigation(val)}>
																{val}
																<Right theme="outline" size="18" fill="#98a1b3" strokeWidth={3} strokeLinecap="butt" />
															</div>
														))}
														<div className="bw_item_active">{selectedNodeData?.title}</div>
													</div>
													<div className="d-flex">
														{
															(Permission[0]?.is_add === '0' || userBidInfo?.permanent_sys_adm === '1') && (
																<button className='BG_mainButton' onClick={() => setBasicModaLinner(true)}>
																	<SettingOutlined />
																	Create Folder
																</button>
															)
														}
														{
															(Permission[0]?.is_update === '0' || userBidInfo?.permanent_sys_adm === '1') && (
																<button className="BG_mainButton uploadButton" onClick={() => setUploadDocModal(true)}>
																	<UploadOutlined />
																	Upload File
																</button>
															)
														}
													</div>
												</div>
												{selectedNodeData && selectedNodeData.children?.length > 0 ? (
													<div className="inner_body">
														<div className="grid__wrapper">
															{selectedNodeData.children?.filter(item => item?.title !== '.db').map((child) => (
																<IB_Item key={child?.key} node={child} />
															))}
														</div>
													</div>
												) : (
													<div className="noFound_wrapper">
														<img src={noFoundImg} alt='img' />
														Folder is empty
													</div>
												)}
											</div>
										)}
									</Col>
								</Row>
							</div>
						</div>
						:
						<div className="bd_doc_folder treeSpinner">
							<Spin size="large" />
						</div>
				}
				<Modal title="Create Folder" open={basicModal} className="bd_model_main shareModal"
					onCancel={() => { handleCancel(); outerFolder.resetFields(); }}
					footer={null}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form name="validateOnly" form={outerFolder} layout="vertical" autoComplete="off" onFinish={(val) => creatBriefFolder(val, 0)}>
						<div className='bd_model_input'>
							<Form.Item label="Folder Name" name="folder_name" rules={[{ required: true, message: 'Folder name is required' }]}>
								<Input placeholder='Enter here' onChange={(e) => handleTrimStart('folder_name', e)} />
							</Form.Item>
						</div>
						<Flex justify='flex-end' align='center'>
							<Button key="back" className='BG_ghostButton' onClick={() => outerFolder.resetFields()}>
								Reset
							</Button>
							<Button key="submit" className='BG_mainButton' style={{ marginLeft: '20px' }} type="primary" htmlType="submit" loading={loadings?.loadingFolder} disabled={loadings?.loadingFolder}>
								Submit
							</Button>
						</Flex>
					</Form>
				</Modal>
				<Modal title="Share" className='bd_model_main shareModal' open={isModalOpen} id='zoom'
					width={700}
					onOk={handleOk} onCancel={handleCancel}
					footer={[
						<>
							<button key="back" className="BG_ghostButton">Reset</button>
							<button key="submit" className="BG_mainButton">Submit</button>
						</>
					]}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form name="validateOnly" layout="vertical" autoComplete="off">
						<div className='bd_model_input'>
							<Row gutter={30}>
							</Row>
						</div>
					</Form>
				</Modal>
				<Modal title="Create Folder" open={basicModaLinner} className="bd_model_main shareModal"
					onCancel={() => { handleCancel(); innerFolder.resetFields() }}
					footer={null}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form name="validateOnly" layout="vertical" autoComplete="off" form={innerFolder} onFinish={(val) => creatBriefFolder(val, selectedNodeData?.key)}>
						<div className='bd_model_input'>
							<Form.Item label="Folder Name" name="folder_name" rules={[{ required: true, message: 'Folder name is required' }]}>
								<Input placeholder='Enter here' onChange={(e) => handleTrimStart('folder_name', e)} />
							</Form.Item>
						</div>
						<Flex justify='flex-end' align='center'>
							<Button key="back" className='BG_ghostButton' onClick={() => innerFolder.resetFields()}>
								Reset
							</Button>
							<Button key="submit" className='BG_mainButton' style={{ marginLeft: '20px' }} type="primary" htmlType="submit" loading={loadings?.loadingFolder} disabled={loadings?.loadingFolder}>
								Submit
							</Button>
						</Flex>
					</Form>
				</Modal>
				<Modal title="Upload Document" open={uploadDocModal} className="bd_model_main uploadDoc_modal"
					onCancel={() => {
						handleClose(); fileForm.resetFields(); setCreateFolderFile(pre => ({ ...pre, document_file_name: [] }))
					}}
					footer={null}
					getContainer={() => document.getElementById('zoom')}
				>
					<Form layout='vertical' name="control-ref" form={fileForm} onFinish={createFile}>
						<Form.Item name="document_file_name" label="Document File" rules={[{ required: true, message: 'File is required' }]}>
							<Upload
								action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
								listType="picture-card"
								multiple
								showUploadList={{ showPreviewIcon: false }}
								fileList={createFolderFile?.document_file_name}
								onChange={(info) => {
									const fileList = info?.fileList?.map((file) => ({
										uid: file?.uid,
										name: file?.name,
										status: 'done',
										originFileObj: file?.originFileObj,
									}));
									handleChange('document_file_name', fileList);
									const selectedFile = fileList[0]?.originFileObj;
									if (selectedFile && fileList.length === 1) {
										const fileName = selectedFile.name;
										fileForm.setFieldsValue({ document_title: fileName });
									}
								}}
							>
								{createFolderFile?.document_file_name?.length >= 20 ? null : uploadButton}
							</Upload>
						</Form.Item>
						<Form.Item name="document_title" label="Document Title" className='doc_inp' rules={[{ required: true, message: 'Document name is required' }]}>
							<Input placeholder='Enter here' onChange={(e) => handleTrimStart('document_title', e)} />
						</Form.Item>
						<div className="btn_flex">
							<Button className="BG_ghostButton" onClick={() => { fileForm.resetFields(); setCreateFolderFile(pre => ({ ...pre, document_file_name: [] })) }}>
								Reset
							</Button>
							<Button className="BG_mainButton" key="submit" type="primary" htmlType="submit" loading={loadings?.loadingFile} disabled={loadings?.loadingFile}>
								Upload
							</Button>
						</div>
					</Form>
				</Modal>
			</div>


			<Delete title={'Briefcase'} open={deleteModal} handleDelete={innerFolderDelete} onClose={() => setDeleteModal(false)} modalData2={modalData} fileDelete={fileDelete} fileName={fileName} />
		</>
	)
}

export default Briefcase