import axios from "axios";
import { bidBaseUrl } from "utils/configurable";



const SetPermission = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/set-briefcash-access`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const GetPermission = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/briefcash-permission-list`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};


export const PermissionAPi = {
    SetPermission,
    GetPermission
}