// // @ts-nocheck
// import React, { useState } from 'react';
// import { Switch } from 'antd';
// import { toast } from 'react-toastify';
// import UpdateProfile from '../rolePermission/UserProfile';
// import { useParams } from 'react-router';
// import { PermissionAPi } from '../../../Services/permission';
// import { useEffect } from 'react';

// const RolePermissions = () => {
//   const { id } = useParams();
//   const [folderCreate, setFolderCreate] = useState(true);
//   const [folderDelete, setFolderDelete] = useState(true);
//   const [filesUpload, setFilesUpload] = useState(true);
//   const notify = (error) => toast.error(error);
//   const notifySuccess = (msg) => toast.success(msg);

//   const UpdatePermission = async (eventName, checked) => {
//     const formData = new URLSearchParams();
//     formData.append('user_id', Number(id));
//     formData.append('event_name', eventName);
//     formData.append('is_allowed', checked.toString());
//     try {
//       const response = await PermissionAPi.SetPermission(formData);
//       if (response?.data?.status == 1) {
//         notifySuccess(response?.data?.message || 'Permission updated successfully');
//       } else {
//         notify(response?.response?.data?.message || 'Error updating permission');
//       }
//     } catch (error) {
//       notify('An error occurred while updating permission');
//     }
//   };

//   const GetPermission = async ()=>{
//     const formData = new URLSearchParams();
//     formData.append('user_id', Number(id));
//     try {
//       const response = await PermissionAPi.GetPermission(formData);
//       if (response?.data?.status == 1) {
//         console.log(response,'response')
//         notifySuccess(response?.data?.message || 'Permission updated successfully');
//       } else {
//         notify(response?.response?.data?.message || 'Error updating permission');
//       }
//     } catch (error) {
//       notify('An error occurred while updating permission');
//     }
//   }

//   useEffect(()=>{
//     GetPermission()
//   },[])
//   const onSwitchChange = (eventName, checked) => {
//     UpdatePermission(eventName, checked);
//   };

//   return (
//     <>
//       <div>
//         <UpdateProfile />
//       </div>
//       <div>
//         <div className="roleManagement_wrapper">
//           <div className="heading_flex">
//             <h3 className="head_title">Briefcash Permission</h3>
//           </div>
//           <div className='main_sec'>
//             <div className='toogle_sec'>
//               <div className='tgl_one'>
//                 <p>Folder Create</p>
//                 <Switch
//                   defaultChecked={folderCreate}
//                   onChange={(checked) => {
//                     setFolderCreate(checked);
//                     onSwitchChange('is_add', checked);
//                   }}
//                 />
//               </div>
//               <div className='tgl_one'>
//                 <p>Folder Delete</p>
//                 <Switch
//                   defaultChecked={folderDelete}
//                   onChange={(checked) => {
//                     setFolderDelete(checked);
//                     onSwitchChange('is_delete', checked);
//                   }}
//                 />
//               </div>
//               <div className='tgl_one'>
//                 <p>Files Upload</p>
//                 <Switch
//                   defaultChecked={filesUpload}
//                   onChange={(checked) => {
//                     setFilesUpload(checked);
//                     onSwitchChange('is_upload', checked);
//                   }}
//                 />
//               </div>
//               <div className='tgl_one'>
//                 <p>Download</p>
//                 <Switch
//                   defaultChecked={filesUpload}
//                   onChange={(checked) => {
//                     setFilesUpload(checked);
//                     onSwitchChange('is_download', checked);
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default RolePermissions;


// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import UpdateProfile from '../rolePermission/UserProfile';
import { useParams } from 'react-router';
import { PermissionAPi } from '../../../Services/permission';

const RolePermissions = () => {
  const { id } = useParams();
  const [folderCreate, setFolderCreate] = useState(false);
  const [folderDelete, setFolderDelete] = useState(false);
  const [filesUpload, setFilesUpload] = useState(false);
  const [download, setDownload] = useState(false);
  const notify = (error) => toast.error(error);
  const notifySuccess = (msg) => toast.success(msg);

  const UpdatePermission = async (eventName, checked) => {
    const formData = new URLSearchParams();
    formData.append('user_id', Number(id));
    formData.append('event_name', eventName);
    formData.append('is_allowed', (!checked).toString()); // Inverted logic

    try {
      const response = await PermissionAPi.SetPermission(formData);
      if (response?.data?.status == 1) {
        notifySuccess(response?.data?.message || 'Permission updated successfully');
        // Optionally refetch permissions here if needed
        GetPermission();
      } else {
        notify(response?.response?.data?.message || 'Error updating permission');
      }
    } catch (error) {
      notify('An error occurred while updating permission');
    }
  };

  const GetPermission = async () => {
    const formData = new URLSearchParams();
    formData.append('user_id', Number(id));

    try {
      const response = await PermissionAPi.GetPermission(formData);
      if (response?.data?.status == 1) {
        const permissions = response?.data?.data;
        setFolderCreate(permissions[0].is_add == 0);
        setFolderDelete(permissions[0].is_delete == 0);
        setFilesUpload(permissions[0].is_update == 0);
        setDownload(permissions[0].is_download == 0);
      } else {
        notify(response?.response?.data?.message || 'Error fetching permissions');
      }
    } catch (error) {
      notify('An error occurred while fetching permissions');
    }
  };

  useEffect(() => {
    GetPermission();
  }, []);

  const onSwitchChange = (eventName, checked) => {
    UpdatePermission(eventName, checked);
  };

  return (
    <>
      <div>
        <UpdateProfile />
      </div>
      <div>
        <div className="roleManagement_wrapper">
          <div className="heading_flex">
            <h3 className="head_title">Briefcash Permission</h3>
          </div>
          <div className='main_sec'>
            <div className='toogle_sec'>
              <div className='tgl_one'>
                <p>Folder Create</p>
                <Switch
                  checked={folderCreate}
                  onChange={(checked) => {
                    setFolderCreate(checked);
                    onSwitchChange('is_add', checked);
                  }}
                />
              </div>
              <div className='tgl_one'>
                <p>Folder Delete</p>
                <Switch
                  checked={folderDelete}
                  onChange={(checked) => {
                    setFolderDelete(checked);
                    onSwitchChange('is_delete', checked);
                  }}
                />
              </div>
              <div className='tgl_one'>
                <p>Files Upload</p>
                <Switch
                  checked={filesUpload}
                  onChange={(checked) => {
                    setFilesUpload(checked);
                    onSwitchChange('is_update', checked);
                  }}
                />
              </div>
              <div className='tgl_one'>
                <p>Files Download</p>
                <Switch
                  checked={download}
                  onChange={(checked) => {
                    setDownload(checked);
                    onSwitchChange('is_download', checked);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RolePermissions;
