// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import './Login.css'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { bidGridAction } from '../../../Redux/actions/common/authAction';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginBanner from '../../../assests/img/login-banner.jpg';
import GgLogo from '../../assets/images/icon.png';
import { useSelector } from 'react-redux';
import ROUTES from 'Constants/Routes';
import { BidAuthServices } from 'Services/bidgrid/auth/bidAuth';
import { userBidInfoAction } from 'Redux/actions/common/userInfoAction';
import { ProfileDetails } from 'Services/Profile/ProfileDetails';
import { COMMON_STRINGS } from 'Constants/Strings';

const initialState = {
    username: '',
    password: ''
}


const BdLogin = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState(initialState)
    const emailInputRef = useRef(null);
    // const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [disableBtn, setDisableBtn] = useState(true)
    const [errorMessage, setErrorMessage] = useState("");
    const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
    const [message, setMessage] = useState('');


    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        setPassword(e.target.value);
        setDisableBtn(data.username === '' || data.password === '');
        setErrorMessage('');
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleKeyDown = e => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };
    const notify = (error) => toast.error(error);
    const notifySuccess = (msg) => toast.success(msg);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setDisableBtn(true)
        if (data.username === '' && data.password === '') {
            notify('please fill all the fields')
        } else if (data.username === '') {
            notify('please fill email')
        } else if (data.password === '') {
            notify('please fill password')
        }
        else {
            setLoader(true)
            const addBidGridLoginDetails = new URLSearchParams();
            addBidGridLoginDetails.append('username', data?.username)
            addBidGridLoginDetails.append('password', data?.password)
            addBidGridLoginDetails.append('ip_Adress', localStorage.getItem('ipAddress'))
            if (localStorage.getItem('setfirebasetoken')) {
                addBidGridLoginDetails.append('web_push_notification_token', localStorage.getItem('setfirebasetoken'))
            }
            try {
                const res = await BidAuthServices.Bidlogin(addBidGridLoginDetails);
                if (res?.data?.status === "1") {
                    dispatch(bidGridAction(res?.data))
                    localStorage.setItem('bidToken', res?.data?.token)
                    const formData = new URLSearchParams();
                    formData.append('user_id', Number(res?.data?.data?.id))
                    try {
                        const response = await ProfileDetails.getuserdetails(formData);
                        console.log(response,'response')
                        if (response?.data?.status == 1) {
                            const userBidInfoData = response.data.data;
                            console.log(userBidInfoData,"userBidInfoDatauserBidInfoData");
                            dispatch(userBidInfoAction({
                                ...userBidInfoData,
                                // Add other properties from userBidInfo as needed
                            }));
                            navigate('/document/dashboard')
                            notifySuccess(res?.data?.message)
                        }

                        else {
                            notify(response?.response?.data?.message)
                        }

                    } catch (error) {
                        console.log(error, 'api error');
                    }
                }
                else {
                    setLoader(false)
                    setErrorMessage(COMMON_STRINGS.INVALID_USER_PASSWORD)
                }
            } catch (error) {
                setLoader(false)
                console.log(error)
            }
        }

        setTimeout(() => {
            setLoader(false)
            setDisableBtn(false)
        }, 2000);
    }


    const handleCheckMsg = () => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (data.username.length === 0) {
            setMessage('');
        }
        if (data.username !== '') {
            if (emailRegex.test(data.username)) {
                setMessage('');
            } else {
                setMessage('Please enter a valid email!');
            }
        }
    }

    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        handleCheckMsg()
    }, [data?.username])

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            navigate(ROUTES.TENDER_GRID)
        }
    };
    useEffect(() => {
        setDisableBtn(false)
    }, [data.username, data.password])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const checkAuth = () => {
        let authForLogin = localStorage.getItem('bidToken');
        if (authForLogin) {
            const currentPath = window.location.pathname;
           
            if (currentPath === ROUTES?.BD_LOGIN) {
                navigate(ROUTES?.DASHBOARD, { replace: true })
                // return <Redirect to={ROUTES.TENDER_GRID} replace />;
                window.open(ROUTES?.DASHBOARD, "_blank")
            }
        }
    };

    useEffect(() => {
        checkAuth();
    }, [])

    return (
        <>
          
            <div className='login_body'>
                <div className="loginBanner" id="switch-cnt">
                    <div className="boBack_button"
                        onClick={() => { navigate(ROUTES.TENDER_GRID); window.location.reload() }}
                    ><ArrowBackIcon tabIndex={0} onKeyPress={handleKeyPress} />
                    </div>
                    <img src={LoginBanner} alt='' />
                    <div className='queries_mail'>Any Queries Email us on: <a href="mailto: business@growthgrids.com">business@growthgrids.com</a></div>
                </div>
                <div className="formWarpper">
                    <div className="loginLogo">
                        <img
                            src={GgLogo}
                            onClick={() => dynamicMenu?.filter(val => val?.fld_id === '28')?.map(item => navigate(`/${item?.action_url}`))}
                            width={60}
                            alt=''
                        />
                    </div>
                    <div className="section-title text-center">
                        <h1>Document Login <span className="one-text"></span></h1>
                    </div>
                    <div className='errormessagename' style={{ color: 'red' }}>
                        {errorMessage}
                    </div>
                    {/* <div className="errormessagename" > {errorMessage} </div> */}

                    <form className="form" method="" action="">



                        <div className="inputGrp">
                            <input onInput={handleChange}
                                ref={emailInputRef}
                                autoComplete="off" onKeyDown={handleKeyDown} value={data?.username} className="form__input" name='username' type="email" placeholder="Enter Email" />
                            <div className='message' style={{ color: 'red' }}>
                                {message}
                            </div>
                            <div className="eye_position">
                                <input
                                    onInput={handleChange}
                                    onKeyDown={handleKeyDown} autoComplete="off" value={data?.password}
                                    className="form__input"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter Password"
                                />
                                {showPassword ? (
                                     <VisibilityIcon className="eye_visible" onClick={togglePasswordVisibility} />
                                ) : (
                                    <VisibilityOffIcon className="eye_visible" onClick={togglePasswordVisibility} />
                                )}
                            </div>
                        </div>



                        <button onClick={handleSubmit} className="mainButton" disabled={disableBtn}>Login</button>

                    </form>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 1000 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


            </div>
        </>
    )
}

export default BdLogin;