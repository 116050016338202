// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, DatePicker, Input, Tabs, Button } from 'antd';
import moment from 'moment';
import { Avatar } from "antd";
import DefaultImage from '../../../assests/img/user.png';
import { Down } from '@icon-park/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from 'BidGrid/components/BidBreadCrumb/Breadcrumb';
import { useLocation, useParams } from 'react-router';
import { ProfileDetails } from 'Services/Profile/ProfileDetails';
import { useSelector } from 'react-redux'
import { userDetailsApi } from 'Services/bidgrid/master/profile/bidProfile';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { AddTenderList } from 'Services/bidgrid/add/AddTender';
import dayjs from 'dayjs';
import PasswordChangeBid from '../Profile/PasswordChangeBid';
const { TextArea } = Input;
const intialState = {
  // profileImg: "",
  firstname: '',
  lastname: '',
  company_id: null,
  email: '',
  contactnumber: '',
  password: '',
  country_id: null,
  state_id: null,
  city_id: null,
  date_of_birth: null,
  full_address_1: '',
  zip_code: '',
  old_password: '',
  confirmPassword: ""
}



const UpdateProfile = () => {
  const [form] = Form.useForm();
  // const [image, setImage] = useState(null);
  const [userName, setUserName] = useState([]);
  const [skeleton, setSkeleton] = useState(true)
  const { BidCountry } = useSelector((state) => state.bidDropdownCalVal)
  const [showNotification, setShowNotification] = useState(false);

  const [personalInputData, setPersonalInputData] = useState(intialState)
  const [profileState, setProfileState] = useState([])
  const [profileCity, setProfileCity] = useState([])
  const notify = (error) => toast.error(error);
  const notifySuccess = (msg) => toast.success(msg);
  const { id } = useParams();





  const BdUserdetails = async () => {
    const formData = new URLSearchParams();
    formData.append('user_id', id)
    try {
      const response = await ProfileDetails.getuserdetails(formData);
      if (response?.data?.status == 1) {
        setUserName(response.data.data)
        // await empDropdownValues()
        const dataVal = response?.data?.data;
        const newObj = {
          // profileImg: userName?.profileimg || '',
          firstname: dataVal?.firstname || '',
          lastname: dataVal?.lastname || '',
          role_id: dataVal?.emp_role_id || '',
          company_id: dataVal?.company_id || '',
          department_id: dataVal?.department_id || '',
          designation_id: dataVal?.designation_id || '',
          jobgrade_id: dataVal?.jobgrade_id || '',
          reporting_mngr_id: dataVal?.reporting_mngr_id || '',
          email: dataVal?.email || '',
          contactnumber: dataVal?.contactnumber || '',
          // password: dataVal?.password || '',
          unit_id: dataVal?.unit_id || '',
          country_id: dataVal?.country_id || '',
          state_id: dataVal?.state_id || '',
          city_id: dataVal?.city_id || '',
          date_of_birth: dataVal?.date_of_birth !== null ? dayjs(dataVal?.date_of_birth) : null,
          full_address_1: dataVal?.full_address_1 || '',
          zip_code: dataVal?.zip_code
        }


        setPersonalInputData((prevState) => {
          return {
            ...prevState,
            ...newObj
          };
        });
        form.setFieldsValue(newObj);

      }
      else {
        setUserName([])
        notify(response?.response?.data?.message)
      }
    } catch (error) {
      console.log(error, 'api error');
    }
  };
  const fetchUserUpdateDetails = async (value) => {
    setShowNotification(true);

    const formdata = new URLSearchParams();
    formdata.append('update_user_id', id)
    formdata.append('firstname', value?.firstname)
    formdata.append('lastname', value?.lastname)
    formdata.append('contactnumber', value?.contactnumber)
    formdata.append('role_id', 0)
    formdata.append('company_id', 0)
    formdata.append('department_id', 0)
    formdata.append('designation_id', 0)
    formdata.append('jobgrade_id', 0)
    formdata.append('reporting_mngr_id', 0)
    formdata.append('business_unit_id', 0)
    formdata.append('email', value?.email)
    // formdata.append('password', value?.password)
    formdata.append('country_id', value?.country_id)
    formdata.append('state_id', value?.state_id)
    formdata.append('city_id', value?.city_id)
    formdata.append('full_address_1', value?.full_address_1)
    formdata.append('zip_code', value?.zip_code)

    formdata.append('date_of_birth', value?.date_of_birth !== null ? dayjs(value?.date_of_birth)?.format('YYYY-MM-DD') : '')
    try {
      const response = await userDetailsApi?.updateUserDetail(formdata);
      if (response?.data?.status == 1) {
        // setPersonalInputData(intialState)
        BdUserdetails()
        handleReset()
        notifySuccess(response?.data?.message)
      }
      else {
        notify(response?.response?.data?.message);
        // handleReset()
      }
    } catch (error) {
      console.log(error, 'Api Error');
    }
    // setTimeout(() => {
    //     setShowNotification(false);
    // }, 2000);
  }
  const getAddTenderState = async () => {
    const formData = new URLSearchParams();
    formData.append('country_id', personalInputData?.country_id);
    try {
      const response = await AddTenderList.getTenderStateList(formData)
      if (response?.data?.status === '1') {
        setProfileState(response?.data?.data)
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }
  const getAddTenderCity = async () => {
    const formData = new URLSearchParams();
    formData.append('state_id', personalInputData?.state_id);
    try {
      const response = await AddTenderList.getTenderCityList(formData)

      if (response?.data?.status === '1') {
        setProfileCity(response?.data?.data)
      }
    } catch (error) {
      console.log(error, 'api erorr')
    }
  }
  useEffect(() => {
    getAddTenderState()

  }, [personalInputData?.country_id])
  useEffect(() => {
    getAddTenderCity()
  }, [personalInputData?.state_id])
  const handleReset = () => {
    setPersonalInputData('');
    form?.resetFields()
  };
  const handleInputChange = (name, value) => {
    setPersonalInputData(
      {
        ...personalInputData,
        [name]: value
      })
  }
  const location = useLocation();
  const val = location?.pathname;
  const str = val.replace('/', '')
  useEffect(() => {
    setSkeleton(true)
    const timer = setTimeout(() => setSkeleton(false), 1000);
    return () => {
      clearTimeout(timer)
    }
  }, [form?.length])
  // passord api --- khushbu
  const postPasswordChange = async (handleResetChangePassword) => {
    const { password, confirmPassword, old_password } = personalInputData;
    try {
      const formData = new URLSearchParams();
      formData.append('user_id', id)
      formData.append('old_password', old_password);
      await form.validateFields();
      const response = await userDetailsApi?.oldPassword(formData);
      if (response.data.status === '1') {
        if (password === confirmPassword) {
          const formData = new URLSearchParams();
          formData.append('user_id', id)
          formData.append('password', password);
          await form.validateFields();
          const response = await userDetailsApi?.changePassword(formData);
          if (response.data.status === '1') {
            setPersonalInputData(response?.data?.message);
            notifySuccess(response?.data?.message)
            handleReset()
            handleResetChangePassword()
          }
          else {
            notify(response?.response?.data?.message);
          }
        }
        else {
          notify('Passwords do not match or do not meet criteria');
        }
      }
      else {
        console.log(response, 'response')
        notify("Old Password is incorrect!!");
      }
    } catch (error) {
      notify("Old Password is incorrect!!");
      console.log("Server error")
    }
  }
  useEffect(() => {
    BdUserdetails()
  }, [])


  const handleProfileChange = (name, e) => {
    const trimmedValue = e.target.value.trimStart(); // Trim leading spaces
    form.setFieldsValue({ [name]: trimmedValue }); //
  };

  const disableFutureDates = (current) => {
    // Disable all dates after today
    return current && current > moment().endOf('day');
  };

  const { userBidInfo } = useSelector((state) => state.userDetails)

  function handleImageChange(event) {
    const file = event.target.files[0];
    updateProfilePicture(file)
  }


  const updateProfilePicture = async (file) => {
    const formData = new FormData();
    formData.append('user_id', id)
    formData.append('files', file);
    try {
      const response = await userDetailsApi.updateUserProfilePic(formData);
      if (response?.data?.status === '1') {
        BdUserdetails()
        notifySuccess('Logo updated successfully');
      } else {
        notify(response?.response?.data?.message);
      }
    } catch (error) {
      console.error('API Error:', error);
      notify('Error updating logo');
    }
  }


  const validateContactNumber = (_, value) => {
    const regex = /^\d{10}$/;

    if (!value || regex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject('Contact number must be a 10-digit number');
  };
  const validateZipCode = (_, value) => {
    if (value && value.length > 6) {
      return Promise.reject('Zip code must not exceed 6 characters');
    }
    return Promise.resolve();
  };
  return (
    <>
      <>
        <Breadcrumb data={str} />
        <div className="bd_profile_main">
          <div className='bd_main_profile_headings'>My Profile</div>
          <div className='bd_main_add' >
            <div className='bd_main_sub_add_profile'>
              <div className='bd_main_sub_image'>
                {/* <img alt='' className="img-thumbnail rounded-circle"
         src={`https://doc.mystorepedia.com/${userName.profileimg_path}/${userName.profileimg}`}
        /> */}
                {userBidInfo?.profileimg ?
                  <img alt='' className="img-thumbnail rounded-circle"
                    src={userBidInfo?.profileimg !== null ? `http://202.131.122.246:3105/api/user/${userBidInfo.profileimg_path}/${userBidInfo.profileimg}` : DefaultImage}
                  /> :
                  <Avatar style={{ width: '40px', height: '40px' }}>
                    {userBidInfo?.firstname !== null ?
                      userBidInfo?.firstname?.charAt(0) : userBidInfo?.userfullname?.charAt(0)}
                  </Avatar>
                }

                <label className='bd_main_update_profile_btn'>
                  Update Profile
                  <input
                    className='input_update_file'
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </label>

                {/* <button onClick={updateProfile}>Update Profile</button> */}
              </div>
              <div className="line_through"></div>
              <div className='bd_main_sub_info'>
                {skeleton ?
                  <>

                    <div className='sub_emp_id'>
                      <Skeleton variant="rectangular" width={120} height={25} />
                      <Skeleton variant="rectangular" width={100} height={25} />
                    </div>
                    <div className='sub_contact'>

                      <p className='sub_pera'> <span className='sub_span'>Email:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
                      <p className='sub_pera'> <span className='sub_span'>Contact:</span><Skeleton variant="rectangular" width={200} height={23} /></p>
                    </div>
                  </>
                  :
                  <>
                    <div className='sub_emp_id'>
                      <span className='sub_emp_head'>{userName.userfullname}</span>
                      <span className='sub_id'>{personalInputData?.role?.map(item => item?.role_name)}</span>
                    </div>
                    <div className='sub_contact'>
                      <p className='sub_pera'> <span className='sub_span'>Email:</span> {userName?.email}</p>
                      <p className='sub_pera'> <span className='sub_span'>Contact:</span>{userName?.contactnumber} </p>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          {/* <--------- tabs -------> */}
          <div className='bd_main_profile_tabs'>
            <div className='profile_tabs_heading'>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: 'Official',
                    key: '1',
                    children:

                      <div className='profile_detail_info_box'>
                        <div className='profile_sub_info'>
                          <Form form={form} name="control-hooks" layout="vertical" autoComplete="off" onFinish={fetchUserUpdateDetails} >
                            <Row gutter={20}>

                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="First Name:" name="firstname" rules={[{ required: true, message: 'First name is required' }]}>
                                  <Input
                                    placeholder="Enter here"
                                    // value={personalInputData?.firstname}
                                    // name='firstname'
                                    onChange={(e) => handleProfileChange('firstname', e)}
                                  />
                                </Form.Item>
                              </Col>

                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="Last Name:" name='lastname' rules={[{ required: true, message: 'Last name is required' }]}>
                                  <Input placeholder="Enter here" onChange={(e) => handleProfileChange('lastname', e)} />
                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="Email:" name='email' rules={[{ required: true, message: 'Email is required' }, {
                                  type: 'email',
                                  message: 'Please enter a valid email address',
                                },]}>
                                  <Input
                                    placeholder="Enter here"
                                    onChange={(e) => handleProfileChange('email', e)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="Contact Number:" name='contactnumber' rules={[{ required: true, message: 'Contact Number is required' }, { validator: validateContactNumber, }]}>
                                  <Input
                                    placeholder="Enter here"
                                    type='number'
                                    onChange={(e) => handleProfileChange('contactnumber', e)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="DOB:" name='date_of_birth' rules={[{ required: true, message: 'Date of birth is required' }]}>
                                  <DatePicker
                                    name='date_of_birth'
                                    placeholder="Select Date"
                                    value={personalInputData?.date_of_birth ? moment(personalInputData.date_of_birth) : null}
                                    onChange={(value) => handleInputChange('date_of_birth', value)}
                                    disabledDate={disableFutureDates}
                                  />

                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="Country:" name='country_id' rules={[{ required: true, message: 'Country is required' }]}>
                                  <Select
                                    showSearch
                                    value={personalInputData?.country_id}
                                    onChange={(value) => handleInputChange('country_id', value)}
                                    name='country_id'
                                    placeholder="Select Country"
                                    options={BidCountry?.map((item, index) => {
                                      return {
                                        value: item?.id,
                                        label: item?.country_name
                                      }
                                    })}
                                    suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                    filterOption={(input, option) =>
                                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="State:" name='state_id' rules={[{ required: true, message: 'State is required' }]}>
                                  <Select
                                    showSearch
                                    value={personalInputData.state_id}
                                    onChange={(value) => handleInputChange('state_id', value)}
                                    options={profileState?.map((item, index) => {
                                      return {
                                        value: item?.id,
                                        label: item?.state_name
                                      }
                                    })}
                                    placeholder="Select State"
                                    name='state_id'
                                    suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                    filterOption={(input, option) =>
                                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                  >

                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="City:" name='city_id' rules={[{ required: true, message: 'City is required' }]}>
                                  <Select
                                    showSearch
                                    value={personalInputData.city_id}
                                    onChange={(value) => handleInputChange('city_id', value)}
                                    options={profileCity?.map((item, index) => {
                                      return {
                                        value: item?.id,
                                        label: item?.city_name
                                      }
                                    })}
                                    name='city_id'
                                    placeholder="Select City"
                                    suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                                    filterOption={(input, option) =>
                                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                  />

                                </Form.Item>
                              </Col>
                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="Zip Code:" name='zip_code'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Zip code is required'
                                    },
                                    {
                                      validator: validateZipCode,
                                    },
                                  ]}

                                // rules={[{ required: true, message: 'Zip code is required' }]}
                                >
                                  <Input
                                    // name='zip_code'
                                    placeholder="Enter here"
                                    type='number'
                                  // value={personalInputData?.zip_code}
                                  // onChange={(e) => handleInputChange('zip_code', e?.target?.value.trimStart())}
                                  />
                                </Form.Item>
                              </Col>

                              <Col sm={12} md={6} xs={24}>
                                <Form.Item label="Full Address:" name='full_address_1' rules={[{ required: true, message: 'Full address is required' }]}>
                                  <TextArea
                                    name='full_address_1'
                                    placeholder="Enter here"
                                    value={personalInputData?.full_address_1}
                                    onChange={(e) => handleInputChange('full_address_1', e?.target?.value)}
                                  />
                                </Form.Item>
                              </Col>

                            </Row>
                            <div className='profile_button_sec'>
                              <Button className='BG_ghostButton' onClick={handleReset}>Reset</Button>
                              {/* <Button className='BG_mainBtton'>Save & Change</Button> */}
                              <button className='BG_mainButton' key="submit" >Save & Change</button>

                            </div>
                          </Form>
                        </div>

                      </div>,
                  },
                  // {
                  //     label: 'Personal',
                  //     key: '2',
                  //     children: <div className='profile_detail_info_box'>
                  //         <div className='profile_sub_info'>
                  //             <Form
                  //                 form={form}
                  //                 layout="vertical"
                  //             >
                  //                 <Row gutter={20}>
                  //                     <Col sm={12} md={6} xs={24}>
                  //                         <Form.Item label="Profile image">

                  //                             <div className='bd_main_sub_image'>
                  //                                 <img className="bd_main_sub_s_img" src={personalInputData?.profileImg || user} alt="user" />
                  //                                 <label className='bd_main_update_profile_btn'>
                  //                                     Update Profile
                  //                                     <input
                  //                                         name='profileImg'
                  //                                         className='input_update_file'
                  //                                         type="file"
                  //                                         accept="image/*"
                  //                                         onChange={handleImageChange}
                  //                                     />
                  //                                 </label>
                  //                             </div>
                  //                         </Form.Item>
                  //                     </Col>



                  //                 </Row >
                  //             </Form >
                  //         </div >

                  //     </div >
                  // },
                  {
                    label: 'Change Password',
                    key: '3',
                    children: <PasswordChangeBid
                      form={form}
                      personalInputData={personalInputData}
                      handleInputChange={handleInputChange}
                      handleReset={handleReset}
                      postPasswordChange={postPasswordChange}
                      setPersonalInputData={setPersonalInputData}
                    />

                  },


                ]}
              />
            </div >
          </div>
        </div>
      </>


    </>
  )
}

export default UpdateProfile