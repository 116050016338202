import { companydetailsAction } from 'Redux/actions/common/companydetailsAction'
import {DYNAMIC_DETAILS_FAILED,DYNAMIC_DETAILS_SUCCESS,DYNAMIC_DETAILS_REQUEST} from '../../constants/common/companydetailsConstant'

const initialState = {
    companydetails: {},
}

export const CompanyDetailsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case DYNAMIC_DETAILS_REQUEST:
            return { ...state }
        case DYNAMIC_DETAILS_SUCCESS:
            return { ...state, companydetails: payload }
        case DYNAMIC_DETAILS_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}